<template>
  <div>
    <div>
      <a href="/">
        <p class="text-redirect">Página não encontrada - Clique aqui para voltar ao sistema</p>
      </a>
    </div>
    <div>
      <svg
        id="errorSVG"
        class="error-svg"
        data-name="errorSVG"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1120 645"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="679.53"
            y1="188.68"
            x2="900.4"
            y2="188.68"
            gradientTransform="matrix(1, 0, 0.19, 1, -114.27, 0)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#757575" />
            <stop offset="1" stop-color="#626261" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="92.08"
            y1="410.47"
            x2="92.08"
            y2="402.93"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#5c5c5c" />
            <stop offset="1" stop-color="#3a3a3a" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="-1146.55"
            y1="-9119.37"
            x2="-1146.55"
            y2="-9125.05"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-4"
            x1="-1146.55"
            y1="-9123.64"
            x2="-1146.55"
            y2="-9119.73"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-5"
            x1="-1147.86"
            y1="-9121.99"
            x2="-1145.24"
            y2="-9121.99"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-6"
            x1="-1146.55"
            y1="-9121.25"
            x2="-1146.55"
            y2="-9123"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-7"
            x1="85.03"
            y1="406.42"
            x2="85.03"
            y2="398.88"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-8"
            x1="-1154.69"
            y1="-9119.39"
            x2="-1154.69"
            y2="-9125.07"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-9"
            x1="-1154.69"
            y1="-9123.66"
            x2="-1154.69"
            y2="-9119.75"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-10"
            x1="-1156"
            y1="-9122"
            x2="-1153.38"
            y2="-9122"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-11"
            x1="-1154.69"
            y1="-9121.27"
            x2="-1154.69"
            y2="-9123.01"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-12"
            x1="84.15"
            y1="415.64"
            x2="84.15"
            y2="408.1"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-13"
            x1="-1155.71"
            y1="-9127.82"
            x2="-1155.71"
            y2="-9133.5"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-14"
            x1="-1155.71"
            y1="-9132.09"
            x2="-1155.71"
            y2="-9128.18"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-15"
            x1="77.1"
            y1="411.59"
            x2="77.1"
            y2="404.05"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-16"
            x1="-1163.85"
            y1="-9127.84"
            x2="-1163.85"
            y2="-9133.52"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-17"
            x1="-1163.85"
            y1="-9132.11"
            x2="-1163.85"
            y2="-9128.2"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-18"
            x1="152.27"
            y1="422.32"
            x2="152.27"
            y2="414.79"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-19"
            x1="-1077.05"
            y1="-9099.54"
            x2="-1077.05"
            y2="-9105.23"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-20"
            x1="-1077.05"
            y1="-9103.82"
            x2="-1077.05"
            y2="-9099.9"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-21"
            x1="-1078.36"
            y1="-9102.16"
            x2="-1075.74"
            y2="-9102.16"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-22"
            x1="-1077.05"
            y1="-9101.42"
            x2="-1077.05"
            y2="-9103.17"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-23"
            x1="145.22"
            y1="418.27"
            x2="145.22"
            y2="410.74"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-24"
            x1="-1085.19"
            y1="-9099.56"
            x2="-1085.19"
            y2="-9105.25"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-25"
            x1="-1085.19"
            y1="-9103.84"
            x2="-1085.19"
            y2="-9099.92"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-26"
            x1="-1086.5"
            y1="-9102.18"
            x2="-1083.88"
            y2="-9102.18"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-27"
            x1="-1085.19"
            y1="-9101.44"
            x2="-1085.19"
            y2="-9103.19"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-28"
            x1="144.34"
            y1="427.5"
            x2="144.34"
            y2="419.96"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-29"
            x1="-1086.21"
            y1="-9107.99"
            x2="-1086.21"
            y2="-9113.68"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-30"
            x1="-1086.21"
            y1="-9112.26"
            x2="-1086.21"
            y2="-9108.35"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-31"
            x1="137.29"
            y1="423.45"
            x2="137.29"
            y2="415.91"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-32"
            x1="-1094.35"
            y1="-9108.01"
            x2="-1094.35"
            y2="-9113.69"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-33"
            x1="-1094.35"
            y1="-9112.28"
            x2="-1094.35"
            y2="-9108.37"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-34"
            x1="641.14"
            y1="395.75"
            x2="641.14"
            y2="388.21"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-35"
            x1="-512.53"
            y1="-8832.09"
            x2="-512.53"
            y2="-8837.78"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-36"
            x1="-512.53"
            y1="-8836.37"
            x2="-512.53"
            y2="-8832.45"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-37"
            x1="-513.85"
            y1="-8834.71"
            x2="-511.22"
            y2="-8834.71"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-38"
            x1="-512.53"
            y1="-8833.97"
            x2="-512.53"
            y2="-8835.72"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-39"
            x1="634.09"
            y1="391.7"
            x2="634.09"
            y2="384.16"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-40"
            x1="-520.68"
            y1="-8832.11"
            x2="-520.68"
            y2="-8837.79"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-41"
            x1="-520.68"
            y1="-8836.38"
            x2="-520.68"
            y2="-8832.47"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-42"
            x1="-521.99"
            y1="-8834.73"
            x2="-519.36"
            y2="-8834.73"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-43"
            x1="-520.68"
            y1="-8833.99"
            x2="-520.68"
            y2="-8835.73"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-44"
            x1="633.21"
            y1="400.93"
            x2="633.21"
            y2="393.39"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-45"
            x1="-521.69"
            y1="-8840.54"
            x2="-521.69"
            y2="-8846.22"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-46"
            x1="-521.69"
            y1="-8844.81"
            x2="-521.69"
            y2="-8840.9"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-47"
            x1="626.16"
            y1="396.88"
            x2="626.16"
            y2="389.34"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-48"
            x1="-529.84"
            y1="-8840.56"
            x2="-529.84"
            y2="-8846.24"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-49"
            x1="-529.83"
            y1="-8844.83"
            x2="-529.83"
            y2="-8840.92"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-50"
            x1="695.12"
            y1="448.87"
            x2="695.12"
            y2="441.33"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-51"
            x1="-450.2"
            y1="-8851.1"
            x2="-450.2"
            y2="-8856.78"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-52"
            x1="-450.2"
            y1="-8855.37"
            x2="-450.2"
            y2="-8851.46"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-53"
            x1="-451.51"
            y1="-8853.72"
            x2="-448.89"
            y2="-8853.72"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-54"
            x1="-450.2"
            y1="-8852.98"
            x2="-450.2"
            y2="-8854.72"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-55"
            x1="688.07"
            y1="444.82"
            x2="688.07"
            y2="437.28"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-56"
            x1="-458.34"
            y1="-8851.12"
            x2="-458.34"
            y2="-8856.8"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-57"
            x1="-458.34"
            y1="-8855.39"
            x2="-458.34"
            y2="-8851.48"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-58"
            x1="-459.65"
            y1="-8853.73"
            x2="-457.03"
            y2="-8853.73"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-59"
            x1="-458.34"
            y1="-8853"
            x2="-458.34"
            y2="-8854.74"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-60"
            x1="687.19"
            y1="454.04"
            x2="687.19"
            y2="446.5"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-61"
            x1="-459.36"
            y1="-8859.55"
            x2="-459.36"
            y2="-8865.23"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-62"
            x1="-459.36"
            y1="-8863.82"
            x2="-459.36"
            y2="-8859.9"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-63"
            x1="680.14"
            y1="449.99"
            x2="680.14"
            y2="442.46"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-64"
            x1="-467.5"
            y1="-8859.56"
            x2="-467.5"
            y2="-8865.25"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-65"
            x1="-467.5"
            y1="-8863.84"
            x2="-467.5"
            y2="-8859.92"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-66"
            x1="392.5"
            y1="584.5"
            x2="392.5"
            y2="576.96"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-67"
            x1="-799.65"
            y1="-9119.87"
            x2="-799.65"
            y2="-9125.56"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-68"
            x1="-799.65"
            y1="-9124.15"
            x2="-799.65"
            y2="-9120.23"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-69"
            x1="-800.97"
            y1="-9122.49"
            x2="-798.34"
            y2="-9122.49"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-70"
            x1="-799.65"
            y1="-9121.75"
            x2="-799.65"
            y2="-9123.5"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-71"
            x1="385.44"
            y1="580.45"
            x2="385.44"
            y2="572.91"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-72"
            x1="-807.79"
            y1="-9119.89"
            x2="-807.79"
            y2="-9125.58"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-73"
            x1="-807.79"
            y1="-9124.17"
            x2="-807.79"
            y2="-9120.25"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-74"
            x1="-809.11"
            y1="-9122.51"
            x2="-806.48"
            y2="-9122.51"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-75"
            x1="-807.79"
            y1="-9121.77"
            x2="-807.79"
            y2="-9123.52"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-76"
            x1="384.56"
            y1="589.67"
            x2="384.56"
            y2="582.13"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-77"
            x1="-808.81"
            y1="-9128.32"
            x2="-808.81"
            y2="-9134"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-78"
            x1="-808.81"
            y1="-9132.59"
            x2="-808.81"
            y2="-9128.68"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-79"
            x1="377.51"
            y1="585.62"
            x2="377.51"
            y2="578.08"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-80"
            x1="-816.95"
            y1="-9128.34"
            x2="-816.95"
            y2="-9134.02"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-81"
            x1="-816.95"
            y1="-9132.61"
            x2="-816.95"
            y2="-9128.7"
            gradientTransform="matrix(0.87, 0.5, 0, -1.15, 1084.63, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-82"
            x1="272.75"
            y1="476.15"
            x2="272.75"
            y2="468.61"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-83"
            x1="-937.93"
            y1="-9085.92"
            x2="-937.93"
            y2="-9091.6"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-84"
            x1="-937.93"
            y1="-9090.19"
            x2="-937.93"
            y2="-9086.28"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-85"
            x1="-939.24"
            y1="-9088.54"
            x2="-936.62"
            y2="-9088.54"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-86"
            x1="-937.93"
            y1="-9087.8"
            x2="-937.93"
            y2="-9089.54"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-87"
            x1="265.7"
            y1="472.1"
            x2="265.7"
            y2="464.56"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-88"
            x1="-946.07"
            y1="-9085.94"
            x2="-946.07"
            y2="-9091.62"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-89"
            x1="-946.07"
            y1="-9090.21"
            x2="-946.07"
            y2="-9086.3"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-90"
            x1="-947.39"
            y1="-9088.55"
            x2="-944.76"
            y2="-9088.55"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-91"
            x1="-946.07"
            y1="-9087.82"
            x2="-946.07"
            y2="-9089.56"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-92"
            x1="264.81"
            y1="481.32"
            x2="264.81"
            y2="473.79"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-93"
            x1="-947.09"
            y1="-9094.37"
            x2="-947.09"
            y2="-9100.05"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-94"
            x1="-947.09"
            y1="-9098.64"
            x2="-947.09"
            y2="-9094.72"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-95"
            x1="257.76"
            y1="477.27"
            x2="257.76"
            y2="469.74"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-96"
            x1="-955.23"
            y1="-9094.38"
            x2="-955.23"
            y2="-9100.07"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-97"
            x1="-955.23"
            y1="-9098.66"
            x2="-955.23"
            y2="-9094.74"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-98"
            x1="161.76"
            y1="435.38"
            x2="161.76"
            y2="427.84"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-99"
            x1="-1066.09"
            y1="-9106.1"
            x2="-1066.09"
            y2="-9111.79"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-100"
            x1="-1066.09"
            y1="-9110.38"
            x2="-1066.09"
            y2="-9106.46"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-101"
            x1="-1067.4"
            y1="-9108.72"
            x2="-1064.77"
            y2="-9108.72"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-102"
            x1="-1066.08"
            y1="-9107.98"
            x2="-1066.08"
            y2="-9109.73"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-103"
            x1="154.71"
            y1="431.33"
            x2="154.71"
            y2="423.79"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-104"
            x1="-1074.23"
            y1="-9106.12"
            x2="-1074.23"
            y2="-9111.81"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-105"
            x1="-1074.23"
            y1="-9110.4"
            x2="-1074.23"
            y2="-9106.48"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-106"
            x1="-1075.54"
            y1="-9108.74"
            x2="-1072.91"
            y2="-9108.74"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-107"
            x1="-1074.23"
            y1="-9108"
            x2="-1074.23"
            y2="-9109.75"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-108"
            x1="153.83"
            y1="440.55"
            x2="153.83"
            y2="433.02"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-109"
            x1="-1075.24"
            y1="-9114.55"
            x2="-1075.24"
            y2="-9120.24"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-110"
            x1="-1075.24"
            y1="-9118.82"
            x2="-1075.24"
            y2="-9114.91"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-111"
            x1="146.78"
            y1="436.51"
            x2="146.78"
            y2="428.97"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-112"
            x1="-1083.39"
            y1="-9114.57"
            x2="-1083.39"
            y2="-9120.25"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-113"
            x1="-1083.39"
            y1="-9118.84"
            x2="-1083.39"
            y2="-9114.93"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-114"
            x1="589.77"
            y1="361.62"
            x2="589.77"
            y2="354.08"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-115"
            x1="-571.86"
            y1="-8828.22"
            x2="-571.86"
            y2="-8833.9"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-116"
            x1="-571.86"
            y1="-8832.49"
            x2="-571.86"
            y2="-8828.58"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-117"
            x1="-573.17"
            y1="-8830.83"
            x2="-570.54"
            y2="-8830.83"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-118"
            x1="-571.85"
            y1="-8830.1"
            x2="-571.85"
            y2="-8831.84"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-119"
            x1="582.72"
            y1="357.57"
            x2="582.72"
            y2="350.03"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-120"
            x1="-580"
            y1="-8828.24"
            x2="-580"
            y2="-8833.92"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-121"
            x1="-580"
            y1="-8832.51"
            x2="-580"
            y2="-8828.59"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-122"
            x1="-581.31"
            y1="-8830.85"
            x2="-578.68"
            y2="-8830.85"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-123"
            x1="-580"
            y1="-8830.12"
            x2="-580"
            y2="-8831.86"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-124"
            x1="581.84"
            y1="366.79"
            x2="581.84"
            y2="359.25"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-125"
            x1="-581.01"
            y1="-8836.66"
            x2="-581.01"
            y2="-8842.35"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-126"
            x1="-581.01"
            y1="-8840.94"
            x2="-581.01"
            y2="-8837.02"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-127"
            x1="574.79"
            y1="362.74"
            x2="574.79"
            y2="355.21"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-128"
            x1="-589.16"
            y1="-8836.68"
            x2="-589.16"
            y2="-8842.37"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-129"
            x1="-589.16"
            y1="-8840.96"
            x2="-589.16"
            y2="-8837.04"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-130"
            x1="386.99"
            y1="120.22"
            x2="386.99"
            y2="112.68"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-131"
            x1="-806.01"
            y1="-8720.55"
            x2="-806.01"
            y2="-8726.24"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-132"
            x1="-806.01"
            y1="-8724.83"
            x2="-806.01"
            y2="-8720.91"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-133"
            x1="-807.32"
            y1="-8723.17"
            x2="-804.69"
            y2="-8723.17"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-134"
            x1="-806.01"
            y1="-8722.43"
            x2="-806.01"
            y2="-8724.18"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-135"
            x1="379.94"
            y1="116.17"
            x2="379.94"
            y2="108.63"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-136"
            x1="-814.15"
            y1="-8720.57"
            x2="-814.15"
            y2="-8726.26"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-137"
            x1="-814.15"
            y1="-8724.85"
            x2="-814.15"
            y2="-8720.93"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-138"
            x1="-815.46"
            y1="-8723.19"
            x2="-812.84"
            y2="-8723.19"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-139"
            x1="-814.15"
            y1="-8722.45"
            x2="-814.15"
            y2="-8724.2"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-140"
            x1="379.06"
            y1="125.4"
            x2="379.06"
            y2="117.86"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-141"
            x1="-815.17"
            y1="-8729"
            x2="-815.17"
            y2="-8734.68"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-142"
            x1="-815.17"
            y1="-8733.27"
            x2="-815.17"
            y2="-8729.36"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-143"
            x1="372.01"
            y1="121.35"
            x2="372.01"
            y2="113.81"
            gradientTransform="matrix(-1, 0, 0, 1, 681.34, 0)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-144"
            x1="-823.31"
            y1="-8729.02"
            x2="-823.31"
            y2="-8734.7"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-145"
            x1="-823.31"
            y1="-8733.29"
            x2="-823.31"
            y2="-8729.38"
            gradientTransform="matrix(-0.87, 0.5, 0, -1.15, -403.3, -9552.69)"
            xlink:href="#linear-gradient-2"
          />
        </defs>

        <path
          d="M767.25,157c56.53,25.83,76.51,54,37.89,75.3-39.07,21.58-98.76,11.69-150.75-11.82-52.88-24.16-76.18-53.59-37.1-75.18C657.76,122.93,717.18,134.1,767.25,157ZM690,200.8c41.68,19.27,70,23.77,83.75,16.19s.35-21.21-41.28-40.22c-40.5-18.51-69.09-24.29-83.28-16.45C636.15,167.51,647.28,181.52,690,200.8Z"
          fill="url(#linear-gradient)"
        />
        <path
          d="M773.73,217C760,224.57,731.66,220.07,690,200.8c-29.72-13.42-44.13-24.28-45.77-32.07h0L644,215.46c39,20.06,90.21,34.61,134.46,26.33l-.1-35-.37-.11C780,210.84,778.6,214.3,773.73,217Z"
          fill="#9e9e9e"
        />
        <path
          d="M681.33,297.55l-43.6-19.91-67,37L542.9,301.92l-54.17-82.49L532,195.53l107.16,48.95,18.15-10,32.06,14.65-18.14,10,43.6,19.92ZM571.15,281.49l.56.26,34-18.76-73.38-34.7-.93.52"
          fill="#626261"
        />
        <polygon
          points="671.23 259.13 670.13 292.46 681.33 297.55 714.83 279.05 671.23 259.13"
          fill="#bdbcbc"
        />
        <polyline
          points="637.73 277.64 606.47 294.92 605.67 262.99 532.29 228.29 532 195.53 639.16 244.48"
          fill="#757575"
        />
        <polygon
          points="605.67 262.99 606.23 295.08 570.85 316.54 571.71 281.75 605.67 262.99"
          fill="#bdbcbc"
        />
        <polygon
          points="657.31 234.46 655.47 285.74 670.13 292.46 671.23 259.13 689.37 249.11 657.31 234.46"
          fill="#757575"
        />
        <polyline
          points="531.36 228.81 529.76 281.91 570.73 316.58 570.9 314.57 571.71 281.75"
          fill="#9e9e9e"
        />
        <polyline points="570.81 316.58 542.9 301.92 529.77 281.92 542.89 279.41" fill="#9e9e9e" />
        <path
          d="M945,160l-43.6-19.92-67,37-27.85-12.72-54.16-82.5L795.68,58l107.17,49,18.14-10,32.07,14.65-18.15,10,43.6,19.92ZM834.83,143.92l.56.26,34-18.76L796,90.72l-.93.52"
          fill="#626261"
        />
        <polygon
          points="934.91 121.56 933.82 154.89 945.02 159.98 978.51 141.48 934.91 121.56"
          fill="#bdbcbc"
        />
        <polyline
          points="901.42 140.06 870.15 157.35 869.35 125.42 795.98 90.72 795.68 57.95 902.85 106.91"
          fill="#757575"
        />
        <polygon
          points="869.35 125.42 869.91 157.51 834.53 178.97 835.39 144.18 869.35 125.42"
          fill="#bdbcbc"
        />
        <polygon
          points="920.99 96.89 919.15 148.17 933.82 154.89 934.91 121.56 953.06 111.54 920.99 96.89"
          fill="#757575"
        />
        <polyline
          points="795.05 91.23 793.45 144.34 834.41 179.01 834.58 176.99 835.39 144.18"
          fill="#9e9e9e"
        />
        <polyline points="834.49 179.01 806.58 164.35 793.46 144.34 806.58 141.84" fill="#9e9e9e" />
        <polygon
          points="864.33 693.99 382.87 415.9 393.26 409.9 874.72 687.99 864.33 693.99"
          fill="#d5d5d5"
        />
        <polygon
          points="404.68 340.24 44.71 548.07 54.18 553.54 414.15 345.71 404.68 340.24"
          fill="#d5d5d5"
        />
        <polygon
          points="687.11 416.34 408.06 577.44 417.53 582.91 696.58 421.81 687.11 416.34"
          fill="#d5d5d5"
        />
        <polygon
          points="595.52 385.7 579.23 395.11 347.26 529.03 330.97 538.44 347.37 547.9 532.02 654.51 533.54 655.39 548.41 663.98 637.6 612.48 640.58 614.21 364.19 773.78 396.98 773.78 676.25 612.54 640.49 591.88 548.31 645.1 363.66 538.5 595.62 404.57 1083.14 686.02 1083.14 667.21 611.91 395.17 595.52 385.7"
          fill="#d5d5d5"
        />
        <polygon
          points="67.59 389.8 51.2 380.33 0.23 350.9 0.23 369.72 34.91 389.74 0.23 409.76 0.23 428.69 51.3 399.2 67.59 389.8"
          fill="#d5d5d5"
        />
        <polyline
          points="598.48 383.99 614.88 393.46 1083.14 663.79 1083.14 644.97 631.17 384.05 595.4 363.4 374.19 234.89 338.64 255.41 559.85 383.92 327.89 517.85 106.22 389.87 502.59 161.02 466.82 140.37 70.45 369.21 0.23 328.67 0.23 347.48 54.16 378.62 70.56 388.08 86.85 378.68 463.93 160.97 466.91 162.69 89.83 380.4 73.54 389.81 89.93 399.27 311.6 527.25 327.99 536.72 344.28 527.31 576.25 393.39 592.54 383.98 576.14 374.52 374.34 257.21 377.3 255.5 579.11 372.81 595.5 382.27"
          fill="#d5d5d5"
        />
        <polygon
          points="530.58 657.1 529.05 656.22 344.4 549.61 308.64 528.96 86.97 400.98 70.57 391.52 54.28 400.92 0.23 432.13 0.23 451.06 70.68 410.39 512.76 665.63 325.44 773.78 358.23 773.78 545.45 665.69 530.58 657.1"
          fill="#d5d5d5"
        />
        <polygon
          points="595.5 382.27 579.11 372.81 377.3 255.5 374.34 257.21 576.14 374.52 592.54 383.98 576.25 393.39 344.28 527.31 327.99 536.72 311.6 527.25 89.93 399.27 73.54 389.81 89.83 380.4 466.91 162.69 463.93 160.97 86.85 378.68 70.56 388.08 54.16 378.62 0.23 347.48 0.23 350.9 51.2 380.33 67.59 389.8 51.3 399.2 0.23 428.69 0.23 432.13 54.28 400.92 70.57 391.52 86.97 400.98 308.64 528.96 344.4 549.61 529.05 656.22 530.58 657.1 545.45 665.69 358.23 773.78 364.19 773.78 640.58 614.21 637.6 612.48 548.41 663.98 533.54 655.39 532.02 654.51 347.37 547.9 330.97 538.44 347.26 529.03 579.23 395.11 595.52 385.7 611.91 395.17 1083.14 667.21 1083.14 663.79 614.88 393.46 598.48 383.99 595.5 382.27"
          fill="#fff"
        />
        <polygon
          points="385.4 378.03 25.43 170.21 34.9 164.74 394.87 372.57 385.4 378.03"
          fill="#d5d5d5"
        />
        <polygon
          points="186.19 175.19 183.01 173.36 624.09 -81.3 589.15 -81.3 144.99 175.14 183.11 197.14 665.38 -81.3 630.45 -81.3 186.19 175.19"
          fill="#d5d5d5"
        />
        <polygon
          points="186.19 175.19 630.45 -81.3 624.09 -81.3 183.01 173.36 186.19 175.19"
          fill="#fff"
        />
        <polygon
          points="484.92 370.04 500.88 360.83 516.73 369.98 521.03 369.98 521.03 387.29 500.75 399 480.6 387.36 480.6 370.05 484.92 370.04"
          fill="#acacac"
        />
        <polygon
          points="514.78 369.45 521.03 369.98 500.88 358.4 490.73 353.28 491.1 365.89 514.78 369.45"
          fill="#acacac"
        />
        <polygon
          points="510.55 373.25 510.88 364.86 490.73 353.22 480.6 370.05 500.75 381.68 510.55 373.25"
          fill="#787878"
        />
        <polygon points="521.03 369.98 500.75 381.68 510.88 364.86 521.03 369.98" fill="#acacac" />
        <polygon
          points="500.75 399 500.75 381.68 480.6 370.05 480.6 387.36 500.75 399"
          fill="#898989"
        />
        <polygon
          points="459.29 384.62 475.25 375.41 491.11 384.56 495.4 384.56 495.4 401.87 475.12 413.58 454.97 401.95 454.97 384.63 459.29 384.62"
          fill="#acacac"
        />
        <polygon
          points="489.15 384.04 495.4 384.56 475.25 372.98 465.1 367.86 465.47 380.47 489.15 384.04"
          fill="#acacac"
        />
        <polygon
          points="484.92 387.83 485.25 379.44 465.1 367.8 454.97 384.63 475.12 396.26 484.92 387.83"
          fill="#787878"
        />
        <polygon points="495.4 384.56 475.12 396.26 485.25 379.44 495.4 384.56" fill="#acacac" />
        <polygon
          points="475.12 413.58 475.12 396.26 454.97 384.63 454.97 401.95 475.12 413.58"
          fill="#898989"
        />
        <polygon
          points="433.66 399.28 449.62 390.07 465.48 399.22 469.77 399.21 469.77 416.53 449.49 428.24 429.34 416.6 429.34 399.29 433.66 399.28"
          fill="#acacac"
        />
        <polygon
          points="463.53 398.69 469.77 399.21 449.62 387.64 439.47 382.52 439.84 395.12 463.53 398.69"
          fill="#acacac"
        />
        <polygon
          points="459.29 402.49 459.62 394.09 439.47 382.46 429.34 399.29 449.49 410.92 459.29 402.49"
          fill="#787878"
        />
        <polygon points="469.77 399.21 449.49 410.92 459.62 394.09 469.77 399.21" fill="#acacac" />
        <polygon
          points="449.49 428.24 449.49 410.92 429.34 399.29 429.34 416.6 449.49 428.24"
          fill="#898989"
        />
        <polygon
          points="591.75 582.24 604.32 574.98 616.82 582.19 620.21 582.18 620.21 595.83 604.22 605.06 588.34 595.89 588.34 582.24 591.75 582.24"
          fill="#acacac"
        />
        <polygon
          points="615.28 581.77 620.21 582.18 604.32 573.06 596.32 569.03 596.61 578.96 615.28 581.77"
          fill="#acacac"
        />
        <polygon
          points="611.95 584.77 612.21 578.15 596.32 568.98 588.34 582.24 604.22 591.41 611.95 584.77"
          fill="#787878"
        />
        <polygon points="620.21 582.18 604.22 591.41 612.21 578.15 620.21 582.18" fill="#acacac" />
        <polygon
          points="604.22 605.06 604.22 591.41 588.34 582.24 588.34 595.89 604.22 605.06"
          fill="#898989"
        />
        <polygon
          points="571.55 593.73 584.12 586.47 596.62 593.68 600 593.68 600 607.33 584.02 616.55 568.14 607.38 568.14 593.73 571.55 593.73"
          fill="#acacac"
        />
        <polygon
          points="595.08 593.27 600 593.68 584.12 584.55 576.12 580.52 576.41 590.45 595.08 593.27"
          fill="#acacac"
        />
        <polygon
          points="591.75 596.26 592 589.64 576.12 580.47 568.14 593.73 584.02 602.9 591.75 596.26"
          fill="#787878"
        />
        <polygon points="600 593.68 584.02 602.9 592 589.64 600 593.68" fill="#acacac" />
        <polygon
          points="584.02 616.55 584.02 602.9 568.14 593.73 568.14 607.38 584.02 616.55"
          fill="#898989"
        />
        <polygon
          points="551.35 605.28 563.92 598.02 576.42 605.23 579.81 605.23 579.81 618.88 563.82 628.1 547.94 618.93 547.94 605.28 551.35 605.28"
          fill="#acacac"
        />
        <polygon
          points="574.88 604.82 579.81 605.23 563.92 596.11 555.92 592.07 556.21 602 574.88 604.82"
          fill="#acacac"
        />
        <polygon
          points="571.55 607.81 571.8 601.19 555.92 592.02 547.94 605.28 563.82 614.46 571.55 607.81"
          fill="#787878"
        />
        <polygon points="579.81 605.23 563.82 614.46 571.8 601.19 579.81 605.23" fill="#acacac" />
        <polygon
          points="563.82 628.1 563.82 614.46 547.94 605.28 547.94 618.93 563.82 628.1"
          fill="#898989"
        />
        <polygon
          points="652.33 453.06 664.9 445.8 677.4 453.02 680.78 453.01 680.78 466.66 664.8 475.89 648.92 466.72 648.92 453.07 652.33 453.06"
          fill="#acacac"
        />
        <polygon
          points="675.86 452.6 680.78 453.01 664.9 443.89 656.9 439.86 657.19 449.79 675.86 452.6"
          fill="#acacac"
        />
        <polygon
          points="672.52 455.59 672.78 448.98 656.9 439.81 648.92 453.07 664.8 462.24 672.52 455.59"
          fill="#787878"
        />
        <polygon points="680.78 453.01 664.8 462.24 672.78 448.98 680.78 453.01" fill="#acacac" />
        <polygon
          points="664.8 475.89 664.8 462.24 648.92 453.07 648.92 466.72 664.8 475.89"
          fill="#898989"
        />
        <polygon
          points="632.13 464.56 644.7 457.3 657.2 464.51 660.58 464.5 660.58 478.15 644.6 487.38 628.72 478.21 628.72 464.56 632.13 464.56"
          fill="#acacac"
        />
        <polygon
          points="655.66 464.1 660.58 464.5 644.7 455.38 636.7 451.35 636.99 461.28 655.66 464.1"
          fill="#acacac"
        />
        <polygon
          points="652.33 467.09 652.58 460.47 636.7 451.3 628.72 464.56 644.6 473.73 652.33 467.09"
          fill="#787878"
        />
        <polygon points="660.58 464.5 644.6 473.73 652.58 460.47 660.58 464.5" fill="#acacac" />
        <polygon
          points="644.6 487.38 644.6 473.73 628.72 464.56 628.72 478.21 644.6 487.38"
          fill="#898989"
        />
        <polygon
          points="611.93 476.11 624.5 468.85 637 476.06 640.38 476.06 640.38 489.7 624.4 498.93 608.52 489.76 608.52 476.11 611.93 476.11"
          fill="#acacac"
        />
        <polygon
          points="635.46 475.64 640.38 476.06 624.5 466.93 616.5 462.9 616.79 472.83 635.46 475.64"
          fill="#acacac"
        />
        <polygon
          points="632.13 478.64 632.38 472.02 616.5 462.85 608.52 476.11 624.4 485.28 632.13 478.64"
          fill="#787878"
        />
        <polygon points="640.38 476.06 624.4 485.28 632.38 472.02 640.38 476.06" fill="#acacac" />
        <polygon
          points="624.4 498.93 624.4 485.28 608.52 476.11 608.52 489.76 624.4 498.93"
          fill="#898989"
        />
        <polygon
          points="67.78 357.29 72.02 359.74 69.9 354.3 67.78 348.85 65.66 351.85 63.54 354.84 67.78 357.29"
          fill="#909090"
        />
        <polygon
          points="89.79 349.44 85.55 338.56 67.78 348.85 72.02 359.74 89.79 349.44"
          fill="#d5d5d5"
        />
        <polygon
          points="324.69 506.35 328.94 508.8 326.81 503.36 324.69 497.92 322.57 500.91 320.45 503.9 324.69 506.35"
          fill="#909090"
        />
        <polygon
          points="346.7 498.5 342.46 487.62 324.69 497.92 328.94 508.8 346.7 498.5"
          fill="#d5d5d5"
        />
        <polygon
          points="540.86 486.82 545.1 489.27 542.98 483.83 540.86 478.39 538.74 481.38 536.62 484.37 540.86 486.82"
          fill="#909090"
        />
        <polygon
          points="562.87 478.98 558.62 468.1 540.86 478.39 545.1 489.27 562.87 478.98"
          fill="#d5d5d5"
        />
        <polygon
          points="123.42 568.53 122 567.7 120.58 568.52 120.58 568.53 120.58 568.53 120.58 575.14 120.58 575.14 120.58 580.51 120.58 580.51 120.58 580.51 120.58 580.51 120.58 580.51 122.01 581.33 123.43 580.51 123.42 580.51 123.43 580.51 123.43 575.14 123.43 573.9 123.43 568.53 123.42 568.53"
          fill="#787878"
        />
        <polygon
          points="120.58 568.53 120.58 575.14 120.58 575.14 120.58 580.51 120.58 580.51 120.58 580.51 122 581.33 122 568.53 120.58 568.53"
          fill="#787878"
        />
        <polygon
          points="122.01 569.35 120.58 568.52 122 567.7 123.43 568.53 122.01 569.35"
          fill="#787878"
        />
        <path
          d="M128.68,568.94a6.68,6.68,0,1,1-13.35,0c0-3.68,3-17.14,6.67-17.14S128.68,565.26,128.68,568.94Z"
          fill="#acacac"
        />
        <polygon
          points="106.18 601.59 104.75 600.76 103.33 601.59 103.33 601.59 103.33 601.59 103.33 608.2 103.33 608.2 103.33 613.57 103.33 613.57 103.33 613.57 103.33 613.57 103.33 613.57 104.76 614.39 106.18 613.57 106.18 613.57 106.18 613.57 106.18 608.2 106.18 606.96 106.18 601.59 106.18 601.59"
          fill="#787878"
        />
        <polygon
          points="103.33 601.59 103.33 608.2 103.33 608.2 103.33 613.57 103.33 613.57 103.33 613.57 104.76 614.39 104.76 601.59 103.33 601.59"
          fill="#787878"
        />
        <polygon
          points="104.76 602.41 103.33 601.59 104.75 600.76 106.18 601.59 104.76 602.41"
          fill="#787878"
        />
        <path
          d="M111.43,602a6.68,6.68,0,0,1-13.35,0c0-3.69,3-17.15,6.68-17.15S111.43,598.32,111.43,602Z"
          fill="#acacac"
        />
        <polygon
          points="109.98 325.25 108.55 324.42 107.13 325.24 107.13 325.25 107.13 325.25 107.13 331.86 107.13 331.86 107.13 337.23 107.13 337.23 107.13 337.23 107.13 337.23 107.13 337.23 108.56 338.05 109.98 337.23 109.98 337.23 109.98 337.23 109.98 331.86 109.98 330.62 109.98 325.25 109.98 325.25"
          fill="#787878"
        />
        <polygon
          points="107.13 325.25 107.13 331.86 107.13 331.86 107.13 337.23 107.13 337.23 107.13 337.23 108.56 338.05 108.56 325.25 107.13 325.25"
          fill="#787878"
        />
        <polygon
          points="108.56 326.07 107.13 325.24 108.55 324.42 109.98 325.25 108.56 326.07"
          fill="#787878"
        />
        <path
          d="M115.23,325.66a6.68,6.68,0,1,1-13.35,0c0-3.68,3-17.14,6.67-17.14S115.23,322,115.23,325.66Z"
          fill="#acacac"
        />
        <polygon
          points="123.28 294.85 121.86 294.02 120.44 294.85 120.44 294.85 120.44 294.85 120.44 301.46 120.44 301.46 120.44 306.83 120.44 306.83 120.44 306.83 120.44 306.83 120.44 306.83 121.87 307.65 123.29 306.83 123.28 306.83 123.29 306.83 123.29 301.46 123.29 300.22 123.29 294.85 123.28 294.85"
          fill="#787878"
        />
        <polygon
          points="120.44 294.85 120.44 301.46 120.44 301.46 120.44 306.83 120.44 306.83 120.44 306.83 121.86 307.65 121.86 294.85 120.44 294.85"
          fill="#787878"
        />
        <polygon
          points="121.87 295.67 120.44 294.85 121.86 294.02 123.29 294.85 121.87 295.67"
          fill="#787878"
        />
        <path
          d="M128.53,295.27a6.67,6.67,0,1,1-13.34,0c0-3.69,3-17.15,6.67-17.15S128.53,291.58,128.53,295.27Z"
          fill="#acacac"
        />
        <polygon
          points="673.56 547.75 672.13 546.92 670.71 547.74 670.71 547.75 670.71 547.75 670.71 554.36 670.71 554.36 670.71 559.72 670.71 559.72 670.71 559.72 670.71 559.73 670.71 559.73 672.14 560.55 673.56 559.73 673.56 559.73 673.56 559.73 673.56 554.36 673.56 553.12 673.56 547.75 673.56 547.75"
          fill="#787878"
        />
        <polygon
          points="670.71 547.75 670.71 554.36 670.71 554.36 670.71 559.72 670.71 559.73 670.71 559.73 672.13 560.55 672.13 547.75 670.71 547.75"
          fill="#787878"
        />
        <polygon
          points="672.14 548.57 670.71 547.74 672.13 546.92 673.56 547.75 672.14 548.57"
          fill="#787878"
        />
        <path
          d="M678.81,548.16a6.68,6.68,0,0,1-13.35,0c0-3.68,3-17.14,6.67-17.14S678.81,544.48,678.81,548.16Z"
          fill="#acacac"
        />
        <polygon
          points="48.25 200.18 35.67 192.92 23.18 200.14 19.79 200.13 19.79 213.78 35.77 223.01 51.66 213.84 51.66 200.19 48.25 200.18"
          fill="#acacac"
        />
        <polygon
          points="24.71 199.72 19.79 200.13 35.67 191.01 43.67 186.97 43.38 196.91 24.71 199.72"
          fill="#acacac"
        />
        <polygon
          points="28.05 202.71 27.79 196.1 43.67 186.93 51.66 200.19 35.77 209.36 28.05 202.71"
          fill="#787878"
        />
        <polygon points="19.79 200.13 35.77 209.36 27.79 196.1 19.79 200.13" fill="#acacac" />
        <polygon
          points="35.77 223.01 35.77 209.36 51.66 200.19 51.66 213.84 35.77 223.01"
          fill="#898989"
        />
        <polygon
          points="68.45 211.68 55.87 204.42 43.38 211.63 39.99 211.62 39.99 225.27 55.97 234.5 71.86 225.33 71.86 211.68 68.45 211.68"
          fill="#acacac"
        />
        <polygon
          points="44.91 211.21 39.99 211.62 55.87 202.5 63.87 198.47 63.58 208.4 44.91 211.21"
          fill="#acacac"
        />
        <polygon
          points="48.25 214.21 47.99 207.59 63.87 198.42 71.86 211.68 55.97 220.85 48.25 214.21"
          fill="#787878"
        />
        <polygon points="39.99 211.62 55.97 220.85 47.99 207.59 39.99 211.62" fill="#acacac" />
        <polygon
          points="55.97 234.5 55.97 220.85 71.86 211.68 71.86 225.33 55.97 234.5"
          fill="#898989"
        />
        <polygon
          points="88.65 223.23 76.07 215.97 63.58 223.18 60.19 223.18 60.19 236.82 76.17 246.05 92.06 236.88 92.06 223.23 88.65 223.23"
          fill="#acacac"
        />
        <polygon
          points="65.11 222.76 60.19 223.18 76.07 214.05 84.07 210.02 83.78 219.95 65.11 222.76"
          fill="#acacac"
        />
        <polygon
          points="68.45 225.76 68.19 219.14 84.07 209.97 92.06 223.23 76.17 232.4 68.45 225.76"
          fill="#787878"
        />
        <polygon points="60.19 223.18 76.17 232.4 68.19 219.14 60.19 223.18" fill="#acacac" />
        <polygon
          points="76.17 246.05 76.17 232.4 92.06 223.23 92.06 236.88 76.17 246.05"
          fill="#898989"
        />
        <g id="car-7">
          <g>
            <path
              d="M94.48,408.22a4.91,4.91,0,0,0-2.23-3.85,2.13,2.13,0,0,0-.85-.3h-.16a1.15,1.15,0,0,0-.41.08,1.63,1.63,0,0,0-1.14.93c0,.15.1.39.33.65a5,5,0,0,0,2.23,3.77,2.25,2.25,0,0,0,.82.29l.08.16a2,2,0,0,0,.67-.29A1.53,1.53,0,0,0,94.48,408.22Z"
              fill="url(#linear-gradient-2)"
            />
            <path
              d="M94,408.48a4.91,4.91,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.27a4.94,4.94,0,0,0,2.23,3.85C93,410.46,94,409.89,94,408.48Z"
              fill="url(#linear-gradient-3)"
            />
            <path
              d="M93.79,408.38a4.57,4.57,0,0,0-2.07-3.57c-1.14-.66-2.07-.13-2.07,1.18a4.57,4.57,0,0,0,2.07,3.57C92.86,410.23,93.79,409.7,93.79,408.38Z"
              fill="url(#linear-gradient-4)"
            />
            <path
              d="M92.86,407.84a2.5,2.5,0,0,0-1.14-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C92.35,408.85,92.86,408.57,92.86,407.84Z"
              fill="url(#linear-gradient-5)"
            />
            <path
              d="M92.52,407.65a1.76,1.76,0,0,0-.8-1.38c-.44-.25-.8,0-.8.46a1.75,1.75,0,0,0,.8,1.37C92.16,408.36,92.52,408.16,92.52,407.65Z"
              fill="url(#linear-gradient-6)"
            />
          </g>
          <g>
            <path
              d="M87.43,404.17a4.91,4.91,0,0,0-2.23-3.85,2.13,2.13,0,0,0-.85-.3h-.16a1.11,1.11,0,0,0-.41.08,1.61,1.61,0,0,0-1.14.94c0,.14.1.38.33.64a5,5,0,0,0,2.23,3.77,2.27,2.27,0,0,0,.81.29l.09.16a2,2,0,0,0,.67-.29A1.53,1.53,0,0,0,87.43,404.17Z"
              fill="url(#linear-gradient-7)"
            />
            <path
              d="M86.9,404.43a4.91,4.91,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.27a4.93,4.93,0,0,0,2.23,3.85C85.9,406.41,86.9,405.84,86.9,404.43Z"
              fill="url(#linear-gradient-8)"
            />
            <path
              d="M86.74,404.33a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.07-.13-2.07,1.18a4.55,4.55,0,0,0,2.07,3.58C85.81,406.18,86.74,405.65,86.74,404.33Z"
              fill="url(#linear-gradient-9)"
            />
            <path
              d="M85.81,403.79a2.53,2.53,0,0,0-1.14-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C85.3,404.81,85.81,404.52,85.81,403.79Z"
              fill="url(#linear-gradient-10)"
            />
            <path
              d="M85.47,403.6a1.77,1.77,0,0,0-.8-1.38c-.44-.25-.8-.05-.8.46a1.79,1.79,0,0,0,.8,1.38C85.11,404.31,85.47,404.11,85.47,403.6Z"
              fill="url(#linear-gradient-11)"
            />
          </g>
          <polygon
            points="94.09 410.99 94.09 410.99 94.09 410.99 94.09 406.94 80.65 399.18 80.65 403.22 74.3 406.89 87.74 414.65 94.09 410.99 94.09 410.99 94.09 410.99"
            fill="#898989"
          />
          <polygon
            points="80.65 399.18 94.09 406.94 87.74 410.61 74.3 402.85 80.65 399.18"
            fill="#c4c4c4"
          />
          <polygon
            points="84.23 405.6 77.63 401.79 83.98 398.12 90.59 401.93 84.23 405.6"
            fill="#c4c4c4"
          />
          <polygon
            points="84.24 408.56 84.23 405.6 90.59 401.93 92.14 405.79 85.81 409.49 84.24 408.56"
            fill="#e1e1e1"
          />
          <path
            d="M90.6,411a2,2,0,0,1-.91,1.58c-.51.29-.92.06-.92-.52a2,2,0,0,1,.92-1.58C90.19,410.21,90.6,410.44,90.6,411Z"
            fill="#5c5c5c"
          />
          <path
            d="M93.68,409.25a2,2,0,0,1-.91,1.57c-.51.29-.92.06-.92-.52a2,2,0,0,1,.92-1.58C93.27,408.43,93.68,408.67,93.68,409.25Z"
            fill="#5c5c5c"
          />
          <polygon
            points="84.24 408.59 84.24 408.55 84.24 405.53 77.63 401.72 77.63 404.68 77.56 404.73 74.3 402.85 74.3 406.89 87.74 414.65 87.74 410.61 84.24 408.59"
            fill="#acacac"
          />
          <path
            d="M86.55,413.39a4.93,4.93,0,0,0-2.23-3.85,2.1,2.1,0,0,0-.85-.29l-.1,0h-.06a1.1,1.1,0,0,0-.41.07,1.64,1.64,0,0,0-1.14.94c-.05.15.1.38.33.65a4.94,4.94,0,0,0,2.23,3.76,2.1,2.1,0,0,0,.81.29l.08.16a2.18,2.18,0,0,0,.68-.28A1.55,1.55,0,0,0,86.55,413.39Z"
            fill="url(#linear-gradient-12)"
          />
          <path
            d="M86,413.65a4.93,4.93,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.27a4.91,4.91,0,0,0,2.23,3.85C85,415.63,86,415.06,86,413.65Z"
            fill="url(#linear-gradient-13)"
          />
          <path
            d="M85.86,413.56A4.59,4.59,0,0,0,83.79,410c-1.15-.66-2.07-.13-2.07,1.19a4.55,4.55,0,0,0,2.07,3.57C84.93,415.4,85.86,414.87,85.86,413.56Z"
            fill="url(#linear-gradient-14)"
          />
          <path
            d="M84.92,413a2.49,2.49,0,0,0-1.13-2c-.63-.37-1.14-.07-1.14.65a2.51,2.51,0,0,0,1.14,2C84.42,414,84.92,413.74,84.92,413Z"
            fill="#c4c4c4"
          />
          <path
            d="M84.59,412.82a1.75,1.75,0,0,0-.8-1.37c-.44-.26-.8-.06-.8.45a1.77,1.77,0,0,0,.8,1.38C84.23,413.53,84.59,413.33,84.59,412.82Z"
            fill="#acacac"
          />
          <path
            d="M79.5,409.34a4.93,4.93,0,0,0-2.23-3.85,2.09,2.09,0,0,0-.86-.29.14.14,0,0,0-.09,0h-.06a1.1,1.1,0,0,0-.41.07,1.64,1.64,0,0,0-1.14.94c0,.15.1.38.33.65a4.94,4.94,0,0,0,2.23,3.76,2.1,2.1,0,0,0,.81.29l.08.16a2.18,2.18,0,0,0,.68-.28A1.55,1.55,0,0,0,79.5,409.34Z"
            fill="url(#linear-gradient-15)"
          />
          <path
            d="M79,409.6a4.94,4.94,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.28a4.91,4.91,0,0,0,2.23,3.84C78,411.58,79,411,79,409.6Z"
            fill="url(#linear-gradient-16)"
          />
          <path
            d="M78.81,409.51a4.57,4.57,0,0,0-2.07-3.57c-1.15-.67-2.07-.14-2.07,1.18a4.55,4.55,0,0,0,2.07,3.57C77.88,411.35,78.81,410.82,78.81,409.51Z"
            fill="url(#linear-gradient-17)"
          />
          <path
            d="M77.87,409a2.51,2.51,0,0,0-1.13-2c-.63-.37-1.14-.07-1.14.65a2.51,2.51,0,0,0,1.14,2C77.36,410,77.87,409.69,77.87,409Z"
            fill="#c4c4c4"
          />
          <path
            d="M77.54,408.77a1.75,1.75,0,0,0-.8-1.37c-.44-.26-.8-.06-.8.45a1.76,1.76,0,0,0,.8,1.38C77.18,409.48,77.54,409.28,77.54,408.77Z"
            fill="#acacac"
          />
          <polygon points="84.24 408.55 84.24 405.53 85.81 409.49 84.24 408.55" fill="#dcdcdc" />
          <path
            d="M90.49,410.84a2,2,0,0,1-.92,1.58c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C90.08,410,90.49,410.26,90.49,410.84Z"
            fill="#fff"
          />
          <path
            d="M93.57,409.06a2,2,0,0,1-.92,1.58c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C93.16,408.25,93.57,408.48,93.57,409.06Z"
            fill="#fff"
          />
        </g>
        <g id="car-6">
          <g>
            <path
              d="M154.67,420.07a4.91,4.91,0,0,0-2.23-3.84,2,2,0,0,0-.86-.3h-.15a1.35,1.35,0,0,0-.41.07,1.64,1.64,0,0,0-1.14.94c0,.15.1.39.33.65a4.91,4.91,0,0,0,2.23,3.76,2.31,2.31,0,0,0,.81.3l.08.15a2,2,0,0,0,.68-.28A1.55,1.55,0,0,0,154.67,420.07Z"
              fill="url(#linear-gradient-18)"
            />
            <path
              d="M154.14,420.33a4.91,4.91,0,0,0-2.23-3.84c-1.23-.71-2.23-.15-2.23,1.27a4.91,4.91,0,0,0,2.23,3.84C153.14,422.31,154.14,421.74,154.14,420.33Z"
              fill="url(#linear-gradient-19)"
            />
            <path
              d="M154,420.24a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.07-.13-2.07,1.18a4.55,4.55,0,0,0,2.07,3.57C153.05,422.08,154,421.55,154,420.24Z"
              fill="url(#linear-gradient-20)"
            />
            <path
              d="M153,419.7a2.49,2.49,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C152.54,420.71,153,420.42,153,419.7Z"
              fill="url(#linear-gradient-21)"
            />
            <path
              d="M152.71,419.51a1.76,1.76,0,0,0-.8-1.38c-.44-.26-.8-.05-.8.45a1.76,1.76,0,0,0,.8,1.38C152.35,420.22,152.71,420,152.71,419.51Z"
              fill="url(#linear-gradient-22)"
            />
          </g>
          <g>
            <path
              d="M147.62,416a4.91,4.91,0,0,0-2.23-3.84,2,2,0,0,0-.86-.3h-.15a1.35,1.35,0,0,0-.41.07,1.64,1.64,0,0,0-1.14.94c-.05.15.1.39.33.65a4.91,4.91,0,0,0,2.23,3.76,2.13,2.13,0,0,0,.81.3l.08.15a2,2,0,0,0,.68-.28A1.55,1.55,0,0,0,147.62,416Z"
              fill="url(#linear-gradient-23)"
            />
            <path
              d="M147.09,416.28a4.91,4.91,0,0,0-2.23-3.84c-1.23-.71-2.23-.14-2.23,1.27a4.91,4.91,0,0,0,2.23,3.84C146.09,418.26,147.09,417.69,147.09,416.28Z"
              fill="url(#linear-gradient-24)"
            />
            <path
              d="M146.93,416.19a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.08-.13-2.08,1.18a4.56,4.56,0,0,0,2.08,3.57C146,418,146.93,417.5,146.93,416.19Z"
              fill="url(#linear-gradient-25)"
            />
            <path
              d="M146,415.65a2.51,2.51,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C145.48,416.66,146,416.37,146,415.65Z"
              fill="url(#linear-gradient-26)"
            />
            <path
              d="M145.66,415.46a1.76,1.76,0,0,0-.8-1.38c-.44-.26-.8-.05-.8.45a1.76,1.76,0,0,0,.8,1.38C145.3,416.17,145.66,416,145.66,415.46Z"
              fill="url(#linear-gradient-27)"
            />
          </g>
          <polygon
            points="154.28 422.84 154.28 422.84 154.28 422.84 154.28 418.79 140.84 411.03 140.84 415.08 134.48 418.75 147.93 426.51 154.28 422.84 154.28 422.84 154.28 422.84"
            fill="#898989"
          />
          <polygon
            points="140.84 411.03 154.28 418.79 147.93 422.46 134.48 414.7 140.84 411.03"
            fill="#c4c4c4"
          />
          <polygon
            points="144.42 417.46 137.82 413.65 144.17 409.98 150.77 413.79 144.42 417.46"
            fill="#c4c4c4"
          />
          <polygon
            points="144.42 420.41 144.42 417.46 150.77 413.79 152.33 417.65 145.99 421.34 144.42 420.41"
            fill="#e1e1e1"
          />
          <path
            d="M150.79,422.88a2,2,0,0,1-.91,1.58c-.51.29-.92.05-.92-.52a2,2,0,0,1,.92-1.58C150.38,422.07,150.79,422.3,150.79,422.88Z"
            fill="#5c5c5c"
          />
          <path
            d="M153.87,421.1a2,2,0,0,1-.92,1.58c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C153.46,420.29,153.87,420.52,153.87,421.1Z"
            fill="#5c5c5c"
          />
          <polygon
            points="144.43 420.44 144.42 420.4 144.42 417.39 137.82 413.58 137.82 416.54 137.75 416.59 134.48 414.7 134.48 418.75 147.93 426.51 147.93 422.46 144.43 420.44"
            fill="#acacac"
          />
          <path
            d="M146.74,425.24a4.91,4.91,0,0,0-2.23-3.84,2.17,2.17,0,0,0-.86-.3h-.15a1.3,1.3,0,0,0-.41.08,1.67,1.67,0,0,0-1.15.93c0,.15.11.39.34.65a5,5,0,0,0,2.23,3.77,2.27,2.27,0,0,0,.81.29l.08.16a2.14,2.14,0,0,0,.68-.29A1.56,1.56,0,0,0,146.74,425.24Z"
            fill="url(#linear-gradient-28)"
          />
          <path
            d="M146.21,425.51a4.91,4.91,0,0,0-2.23-3.85c-1.24-.71-2.23-.14-2.23,1.27a4.9,4.9,0,0,0,2.23,3.85C145.21,427.49,146.21,426.92,146.21,425.51Z"
            fill="url(#linear-gradient-29)"
          />
          <path
            d="M146.05,425.41a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.08-.13-2.08,1.18A4.56,4.56,0,0,0,144,426.6C145.12,427.26,146.05,426.73,146.05,425.41Z"
            fill="url(#linear-gradient-30)"
          />
          <path
            d="M145.11,424.87a2.53,2.53,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C144.6,425.89,145.11,425.59,145.11,424.87Z"
            fill="#c4c4c4"
          />
          <path
            d="M144.78,424.68a1.77,1.77,0,0,0-.8-1.38c-.45-.25-.8-.05-.8.46a1.76,1.76,0,0,0,.8,1.38C144.42,425.39,144.78,425.19,144.78,424.68Z"
            fill="#acacac"
          />
          <path
            d="M139.69,421.2a4.91,4.91,0,0,0-2.23-3.85,2.28,2.28,0,0,0-.86-.3h-.15a1.3,1.3,0,0,0-.41.08,1.65,1.65,0,0,0-1.15.94c0,.15.11.38.34.64a5,5,0,0,0,2.23,3.77,2.27,2.27,0,0,0,.81.29l.08.16a2.14,2.14,0,0,0,.68-.29A1.55,1.55,0,0,0,139.69,421.2Z"
            fill="url(#linear-gradient-31)"
          />
          <path
            d="M139.15,421.46a4.9,4.9,0,0,0-2.22-3.85c-1.24-.71-2.23-.14-2.23,1.27a4.9,4.9,0,0,0,2.23,3.85C138.16,423.44,139.15,422.87,139.15,421.46Z"
            fill="url(#linear-gradient-32)"
          />
          <path
            d="M139,421.37a4.57,4.57,0,0,0-2.07-3.58c-1.15-.66-2.08-.13-2.08,1.18a4.56,4.56,0,0,0,2.08,3.58C138.07,423.21,139,422.68,139,421.37Z"
            fill="url(#linear-gradient-33)"
          />
          <path
            d="M138.06,420.83a2.55,2.55,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C137.55,421.84,138.06,421.55,138.06,420.83Z"
            fill="#c4c4c4"
          />
          <path
            d="M137.72,420.63a1.76,1.76,0,0,0-.79-1.38c-.45-.25-.8-.05-.8.46a1.76,1.76,0,0,0,.8,1.38C137.37,421.34,137.72,421.14,137.72,420.63Z"
            fill="#acacac"
          />
          <polygon points="144.42 420.4 144.42 417.39 145.99 421.34 144.42 420.4" fill="#dcdcdc" />
          <path
            d="M150.68,422.7a2,2,0,0,1-.92,1.57c-.5.3-.91.06-.91-.52a2,2,0,0,1,.91-1.57C150.27,421.88,150.68,422.12,150.68,422.7Z"
            fill="#fff"
          />
          <path
            d="M153.75,420.92a2,2,0,0,1-.91,1.58c-.51.29-.91.05-.91-.52a2,2,0,0,1,.91-1.58C153.35,420.11,153.75,420.34,153.75,420.92Z"
            fill="#fff"
          />
        </g>
        <g id="car-1">
          <g>
            <path
              d="M643.54,393.5a4.91,4.91,0,0,0-2.23-3.84,2,2,0,0,0-.86-.3.14.14,0,0,0-.09,0h-.06a1.35,1.35,0,0,0-.41.07,1.64,1.64,0,0,0-1.14.94c0,.15.1.39.33.65a4.93,4.93,0,0,0,2.23,3.76,2.31,2.31,0,0,0,.81.3l.08.15a2.18,2.18,0,0,0,.68-.28A1.55,1.55,0,0,0,643.54,393.5Z"
              fill="url(#linear-gradient-34)"
            />
            <path
              d="M643,393.76a4.91,4.91,0,0,0-2.23-3.84c-1.23-.71-2.23-.15-2.23,1.27a4.91,4.91,0,0,0,2.23,3.84C642,395.74,643,395.17,643,393.76Z"
              fill="url(#linear-gradient-35)"
            />
            <path
              d="M642.85,393.67a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.07-.14-2.07,1.18a4.55,4.55,0,0,0,2.07,3.57C641.92,395.51,642.85,395,642.85,393.67Z"
              fill="url(#linear-gradient-36)"
            />
            <path
              d="M641.91,393.13a2.49,2.49,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C641.41,394.14,641.91,393.85,641.91,393.13Z"
              fill="url(#linear-gradient-37)"
            />
            <path
              d="M641.58,392.94a1.76,1.76,0,0,0-.8-1.38c-.44-.26-.8-.05-.8.45a1.76,1.76,0,0,0,.8,1.38C641.22,393.65,641.58,393.44,641.58,392.94Z"
              fill="url(#linear-gradient-38)"
            />
          </g>
          <g>
            <path
              d="M636.49,389.45a4.91,4.91,0,0,0-2.23-3.84,2,2,0,0,0-.86-.3h-.15a1.35,1.35,0,0,0-.41.07,1.68,1.68,0,0,0-1.15.94c0,.15.11.39.34.65a4.93,4.93,0,0,0,2.23,3.76,2.31,2.31,0,0,0,.81.3l.08.15a2.28,2.28,0,0,0,.68-.28A1.55,1.55,0,0,0,636.49,389.45Z"
              fill="url(#linear-gradient-39)"
            />
            <path
              d="M636,389.71a4.91,4.91,0,0,0-2.23-3.84c-1.23-.71-2.23-.14-2.23,1.27a4.91,4.91,0,0,0,2.23,3.84C635,391.69,636,391.12,636,389.71Z"
              fill="url(#linear-gradient-40)"
            />
            <path
              d="M635.8,389.62a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.08-.13-2.08,1.18a4.56,4.56,0,0,0,2.08,3.57C634.87,391.46,635.8,390.93,635.8,389.62Z"
              fill="url(#linear-gradient-41)"
            />
            <path
              d="M634.86,389.08a2.51,2.51,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C634.35,390.09,634.86,389.8,634.86,389.08Z"
              fill="url(#linear-gradient-42)"
            />
            <path
              d="M634.53,388.89a1.76,1.76,0,0,0-.8-1.38c-.44-.26-.8-.05-.8.45a1.76,1.76,0,0,0,.8,1.38C634.17,389.6,634.53,389.39,634.53,388.89Z"
              fill="url(#linear-gradient-43)"
            />
          </g>
          <polygon
            points="643.15 396.27 643.15 396.27 643.15 396.27 643.15 392.22 629.71 384.46 629.71 388.51 623.35 392.18 636.79 399.94 643.15 396.27 643.15 396.27 643.15 396.27"
            fill="#898989"
          />
          <polygon
            points="629.71 384.46 643.15 392.22 636.79 395.89 623.35 388.13 629.71 384.46"
            fill="#c4c4c4"
          />
          <polygon
            points="633.29 390.89 626.69 387.08 633.04 383.41 639.64 387.22 633.29 390.89"
            fill="#c4c4c4"
          />
          <polygon
            points="633.29 393.84 633.29 390.89 639.64 387.22 641.2 391.08 634.86 394.77 633.29 393.84"
            fill="#e1e1e1"
          />
          <path
            d="M639.66,396.31a2,2,0,0,1-.91,1.58c-.51.29-.92.05-.92-.53a2,2,0,0,1,.92-1.57C639.25,395.5,639.66,395.73,639.66,396.31Z"
            fill="#5c5c5c"
          />
          <path
            d="M642.74,394.53a2,2,0,0,1-.92,1.58c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C642.33,393.72,642.74,394,642.74,394.53Z"
            fill="#5c5c5c"
          />
          <polygon
            points="633.3 393.87 633.29 393.83 633.29 390.82 626.69 387.01 626.69 389.97 626.62 390.02 623.35 388.13 623.35 392.18 636.79 399.94 636.79 395.89 633.3 393.87"
            fill="#acacac"
          />
          <path
            d="M635.61,398.67a4.91,4.91,0,0,0-2.23-3.84,2.17,2.17,0,0,0-.86-.3h-.15a1.3,1.3,0,0,0-.41.07,1.7,1.7,0,0,0-1.15.94c0,.15.11.39.34.65a5,5,0,0,0,2.23,3.77,2.21,2.21,0,0,0,.81.29l.08.16a2.14,2.14,0,0,0,.68-.29A1.56,1.56,0,0,0,635.61,398.67Z"
            fill="url(#linear-gradient-44)"
          />
          <path
            d="M635.07,398.93a4.9,4.9,0,0,0-2.22-3.84c-1.24-.71-2.23-.14-2.23,1.27a4.92,4.92,0,0,0,2.23,3.85C634.08,400.92,635.07,400.35,635.07,398.93Z"
            fill="url(#linear-gradient-45)"
          />
          <path
            d="M634.92,398.84a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.08-.13-2.08,1.18a4.56,4.56,0,0,0,2.08,3.57C634,400.69,634.92,400.16,634.92,398.84Z"
            fill="url(#linear-gradient-46)"
          />
          <path
            d="M634,398.3a2.53,2.53,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C633.47,399.31,634,399,634,398.3Z"
            fill="#c4c4c4"
          />
          <path
            d="M633.64,398.11a1.76,1.76,0,0,0-.79-1.38c-.45-.26-.8-.05-.8.46a1.76,1.76,0,0,0,.8,1.38C633.29,398.82,633.64,398.62,633.64,398.11Z"
            fill="#acacac"
          />
          <path
            d="M628.56,394.63a4.91,4.91,0,0,0-2.23-3.85,2.33,2.33,0,0,0-.86-.3h-.15a1.07,1.07,0,0,0-.41.08,1.65,1.65,0,0,0-1.15.94c0,.14.11.38.34.64a5,5,0,0,0,2.23,3.77,2.21,2.21,0,0,0,.81.29l.08.16a2.14,2.14,0,0,0,.68-.29A1.55,1.55,0,0,0,628.56,394.63Z"
            fill="url(#linear-gradient-47)"
          />
          <path
            d="M628,394.89a4.89,4.89,0,0,0-2.23-3.85c-1.23-.71-2.22-.14-2.22,1.27a4.92,4.92,0,0,0,2.22,3.85C627,396.87,628,396.3,628,394.89Z"
            fill="url(#linear-gradient-48)"
          />
          <path
            d="M627.87,394.79a4.56,4.56,0,0,0-2.08-3.57c-1.14-.66-2.07-.13-2.07,1.18a4.59,4.59,0,0,0,2.07,3.58C626.94,396.64,627.87,396.11,627.87,394.79Z"
            fill="url(#linear-gradient-49)"
          />
          <path
            d="M626.93,394.25a2.51,2.51,0,0,0-1.14-2c-.62-.36-1.13-.07-1.13.65a2.51,2.51,0,0,0,1.13,2C626.42,395.27,626.93,395,626.93,394.25Z"
            fill="#c4c4c4"
          />
          <path
            d="M626.59,394.06a1.74,1.74,0,0,0-.8-1.38c-.44-.25-.79-.05-.79.46a1.78,1.78,0,0,0,.79,1.38C626.24,394.77,626.59,394.57,626.59,394.06Z"
            fill="#acacac"
          />
          <polygon
            points="633.29 393.83 633.29 390.82 634.86 394.77 633.29 393.83"
            fill="#dcdcdc"
          />
          <path
            d="M639.55,396.13a2,2,0,0,1-.92,1.57c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C639.14,395.31,639.55,395.55,639.55,396.13Z"
            fill="#fff"
          />
          <path
            d="M642.62,394.35a2,2,0,0,1-.91,1.58c-.51.29-.91.05-.91-.53a2,2,0,0,1,.91-1.57C642.22,393.54,642.62,393.77,642.62,394.35Z"
            fill="#fff"
          />
        </g>
        <g id="car-2">
          <g>
            <path
              d="M697.52,446.62a4.91,4.91,0,0,0-2.23-3.85,2.29,2.29,0,0,0-.85-.3h-.16a1.42,1.42,0,0,0-.41.08,1.61,1.61,0,0,0-1.14.94c-.05.15.1.38.33.65a5,5,0,0,0,2.23,3.76,2.27,2.27,0,0,0,.81.29l.09.16a2,2,0,0,0,.67-.29A1.53,1.53,0,0,0,697.52,446.62Z"
              fill="url(#linear-gradient-50)"
            />
            <path
              d="M697,446.88a4.91,4.91,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.27a4.93,4.93,0,0,0,2.23,3.85C696,448.86,697,448.29,697,446.88Z"
              fill="url(#linear-gradient-51)"
            />
            <path
              d="M696.83,446.79a4.57,4.57,0,0,0-2.07-3.58c-1.15-.66-2.07-.13-2.07,1.18a4.55,4.55,0,0,0,2.07,3.58C695.9,448.63,696.83,448.1,696.83,446.79Z"
              fill="url(#linear-gradient-52)"
            />
            <path
              d="M695.9,446.25a2.5,2.5,0,0,0-1.14-2c-.63-.37-1.14-.08-1.14.64a2.53,2.53,0,0,0,1.14,2C695.39,447.26,695.9,447,695.9,446.25Z"
              fill="url(#linear-gradient-53)"
            />
            <path
              d="M695.56,446.05a1.77,1.77,0,0,0-.8-1.38c-.44-.25-.8-.05-.8.46a1.79,1.79,0,0,0,.8,1.38C695.2,446.76,695.56,446.56,695.56,446.05Z"
              fill="url(#linear-gradient-54)"
            />
          </g>
          <g>
            <path
              d="M690.47,442.57a4.91,4.91,0,0,0-2.23-3.85,2.29,2.29,0,0,0-.85-.3h-.16a1.1,1.1,0,0,0-.41.07,1.61,1.61,0,0,0-1.14.94c0,.15.1.38.33.65a4.94,4.94,0,0,0,2.23,3.76,2.27,2.27,0,0,0,.81.29l.08.16a2,2,0,0,0,.68-.29A1.52,1.52,0,0,0,690.47,442.57Z"
              fill="url(#linear-gradient-55)"
            />
            <path
              d="M689.94,442.83a4.91,4.91,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.27a4.91,4.91,0,0,0,2.23,3.85C688.94,444.81,689.94,444.24,689.94,442.83Z"
              fill="url(#linear-gradient-56)"
            />
            <path
              d="M689.78,442.74a4.57,4.57,0,0,0-2.07-3.58c-1.15-.66-2.07-.13-2.07,1.18a4.55,4.55,0,0,0,2.07,3.58C688.85,444.58,689.78,444.05,689.78,442.74Z"
              fill="url(#linear-gradient-57)"
            />
            <path
              d="M688.85,442.2a2.5,2.5,0,0,0-1.14-2c-.63-.37-1.14-.08-1.14.64a2.5,2.5,0,0,0,1.14,2C688.34,443.21,688.85,442.92,688.85,442.2Z"
              fill="url(#linear-gradient-58)"
            />
            <path
              d="M688.51,442a1.79,1.79,0,0,0-.8-1.38c-.44-.25-.8-.05-.8.46a1.77,1.77,0,0,0,.8,1.38C688.15,442.71,688.51,442.51,688.51,442Z"
              fill="url(#linear-gradient-59)"
            />
          </g>
          <polygon
            points="697.13 449.39 697.13 449.39 697.13 449.39 697.13 445.34 683.69 437.58 683.69 441.63 677.33 445.29 690.78 453.06 697.13 449.39 697.13 449.39 697.13 449.39"
            fill="#898989"
          />
          <polygon
            points="683.69 437.58 697.13 445.34 690.78 449.01 677.33 441.25 683.69 437.58"
            fill="#c4c4c4"
          />
          <polygon
            points="687.27 444 680.67 440.19 687.02 436.52 693.63 440.33 687.27 444"
            fill="#c4c4c4"
          />
          <polygon
            points="687.28 446.96 687.27 444 693.63 440.33 695.18 444.19 688.84 447.89 687.28 446.96"
            fill="#e1e1e1"
          />
          <path
            d="M693.64,449.42a2,2,0,0,1-.91,1.58c-.51.29-.92.06-.92-.52a2,2,0,0,1,.92-1.58C693.23,448.61,693.64,448.85,693.64,449.42Z"
            fill="#5c5c5c"
          />
          <path
            d="M696.72,447.65a2,2,0,0,1-.91,1.58c-.51.29-.92,0-.92-.53a2,2,0,0,1,.92-1.57C696.31,446.83,696.72,447.07,696.72,447.65Z"
            fill="#5c5c5c"
          />
          <polygon
            points="687.28 446.99 687.28 446.95 687.28 443.94 680.67 440.12 680.67 443.08 680.6 443.13 677.33 441.25 677.33 445.29 690.78 453.06 690.78 449.01 687.28 446.99"
            fill="#acacac"
          />
          <path
            d="M689.59,451.79a4.94,4.94,0,0,0-2.23-3.85,2.09,2.09,0,0,0-.86-.29.14.14,0,0,0-.09,0h-.06a1.35,1.35,0,0,0-.41.07,1.64,1.64,0,0,0-1.14.94c0,.15.1.39.33.65a4.91,4.91,0,0,0,2.23,3.76,2.31,2.31,0,0,0,.81.3l.08.15a2.18,2.18,0,0,0,.68-.28A1.55,1.55,0,0,0,689.59,451.79Z"
            fill="url(#linear-gradient-60)"
          />
          <path
            d="M689.06,452.05a4.91,4.91,0,0,0-2.23-3.84c-1.23-.72-2.23-.15-2.23,1.27a4.91,4.91,0,0,0,2.23,3.84C688.06,454,689.06,453.46,689.06,452.05Z"
            fill="url(#linear-gradient-61)"
          />
          <path
            d="M688.9,452a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.07-.14-2.07,1.18a4.55,4.55,0,0,0,2.07,3.57C688,453.8,688.9,453.27,688.9,452Z"
            fill="url(#linear-gradient-62)"
          />
          <path
            d="M688,451.42a2.49,2.49,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C687.46,452.43,688,452.14,688,451.42Z"
            fill="#c4c4c4"
          />
          <path
            d="M687.63,451.23a1.76,1.76,0,0,0-.8-1.38c-.44-.26-.8-.05-.8.45a1.76,1.76,0,0,0,.8,1.38C687.27,451.94,687.63,451.73,687.63,451.23Z"
            fill="#acacac"
          />
          <path
            d="M682.54,447.74a4.91,4.91,0,0,0-2.23-3.84,2,2,0,0,0-.86-.3h-.15a1.1,1.1,0,0,0-.41.07,1.64,1.64,0,0,0-1.14.94c0,.15.1.39.33.65a4.93,4.93,0,0,0,2.23,3.76,2.31,2.31,0,0,0,.81.3l.08.15a2.28,2.28,0,0,0,.68-.28A1.55,1.55,0,0,0,682.54,447.74Z"
            fill="url(#linear-gradient-63)"
          />
          <path
            d="M682,448a4.91,4.91,0,0,0-2.23-3.84c-1.23-.72-2.23-.14-2.23,1.27a4.91,4.91,0,0,0,2.23,3.84C681,450,682,449.41,682,448Z"
            fill="url(#linear-gradient-64)"
          />
          <path
            d="M681.85,447.91a4.57,4.57,0,0,0-2.07-3.57c-1.15-.66-2.07-.14-2.07,1.18a4.55,4.55,0,0,0,2.07,3.57C680.92,449.75,681.85,449.22,681.85,447.91Z"
            fill="url(#linear-gradient-65)"
          />
          <path
            d="M680.91,447.37a2.51,2.51,0,0,0-1.13-2c-.63-.36-1.14-.07-1.14.65a2.5,2.5,0,0,0,1.14,2C680.4,448.38,680.91,448.09,680.91,447.37Z"
            fill="#c4c4c4"
          />
          <path
            d="M680.58,447.18a1.76,1.76,0,0,0-.8-1.38c-.44-.26-.8-.05-.8.45a1.76,1.76,0,0,0,.8,1.38C680.22,447.89,680.58,447.68,680.58,447.18Z"
            fill="#acacac"
          />
          <polygon
            points="687.28 446.95 687.28 443.94 688.84 447.89 687.28 446.95"
            fill="#dcdcdc"
          />
          <path
            d="M693.53,449.24a2,2,0,0,1-.92,1.58c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C693.12,448.43,693.53,448.66,693.53,449.24Z"
            fill="#fff"
          />
          <path
            d="M696.61,447.46a2,2,0,0,1-.92,1.58c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C696.2,446.65,696.61,446.89,696.61,447.46Z"
            fill="#fff"
          />
        </g>
        <g id="car-5">
          <g>
            <path
              d="M394.9,582.24a4.91,4.91,0,0,0-2.23-3.84,2.22,2.22,0,0,0-.86-.3h-.15a1.07,1.07,0,0,0-.41.08,1.64,1.64,0,0,0-1.15.93c0,.15.11.39.34.65a4.91,4.91,0,0,0,2.23,3.76,2.07,2.07,0,0,0,.81.3l.08.16a2,2,0,0,0,.67-.29A1.54,1.54,0,0,0,394.9,582.24Z"
              fill="url(#linear-gradient-66)"
            />
            <path
              d="M394.36,582.5a4.91,4.91,0,0,0-2.23-3.84c-1.23-.71-2.23-.14-2.23,1.27a4.91,4.91,0,0,0,2.23,3.84C393.36,584.49,394.36,583.92,394.36,582.5Z"
              fill="url(#linear-gradient-67)"
            />
            <path
              d="M394.21,582.41a4.56,4.56,0,0,0-2.08-3.57c-1.14-.66-2.07-.13-2.07,1.18a4.57,4.57,0,0,0,2.07,3.57C393.28,584.25,394.21,583.73,394.21,582.41Z"
              fill="url(#linear-gradient-68)"
            />
            <path
              d="M393.27,581.87a2.5,2.5,0,0,0-1.14-2c-.63-.36-1.13-.07-1.13.65a2.49,2.49,0,0,0,1.13,2C392.76,582.88,393.27,582.59,393.27,581.87Z"
              fill="url(#linear-gradient-69)"
            />
            <path
              d="M392.93,581.68a1.76,1.76,0,0,0-.8-1.38c-.44-.25-.8,0-.8.46a1.75,1.75,0,0,0,.8,1.37C392.57,582.39,392.93,582.18,392.93,581.68Z"
              fill="url(#linear-gradient-70)"
            />
          </g>
          <g>
            <path
              d="M387.85,578.19a4.91,4.91,0,0,0-2.23-3.84,2.22,2.22,0,0,0-.86-.3h-.16a1.06,1.06,0,0,0-.4.08,1.64,1.64,0,0,0-1.15.93c0,.15.11.39.34.65a5,5,0,0,0,2.23,3.77,2.21,2.21,0,0,0,.81.29l.08.16a2,2,0,0,0,.67-.29A1.54,1.54,0,0,0,387.85,578.19Z"
              fill="url(#linear-gradient-71)"
            />
            <path
              d="M387.31,578.46a4.91,4.91,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.27a4.93,4.93,0,0,0,2.23,3.85C386.31,580.44,387.31,579.87,387.31,578.46Z"
              fill="url(#linear-gradient-72)"
            />
            <path
              d="M387.16,578.36a4.56,4.56,0,0,0-2.08-3.57c-1.14-.66-2.07-.13-2.07,1.18a4.54,4.54,0,0,0,2.07,3.57C386.23,580.21,387.16,579.68,387.16,578.36Z"
              fill="url(#linear-gradient-73)"
            />
            <path
              d="M386.22,577.82a2.5,2.5,0,0,0-1.14-2c-.62-.36-1.13-.07-1.13.65a2.51,2.51,0,0,0,1.13,2C385.71,578.83,386.22,578.54,386.22,577.82Z"
              fill="url(#linear-gradient-74)"
            />
            <path
              d="M385.88,577.63a1.77,1.77,0,0,0-.8-1.38c-.44-.26-.8,0-.8.46a1.75,1.75,0,0,0,.8,1.37C385.52,578.34,385.88,578.14,385.88,577.63Z"
              fill="url(#linear-gradient-75)"
            />
          </g>
          <polygon
            points="394.51 585.01 394.51 585.01 394.51 585.01 394.51 580.97 381.06 573.21 381.06 577.25 374.71 580.92 388.15 588.68 394.51 585.01 394.51 585.01 394.51 585.01"
            fill="#898989"
          />
          <polygon
            points="381.06 573.21 394.51 580.97 388.15 584.63 374.71 576.88 381.06 573.21"
            fill="#c4c4c4"
          />
          <polygon
            points="384.64 579.63 378.04 575.82 384.4 572.15 391 575.96 384.64 579.63"
            fill="#c4c4c4"
          />
          <polygon
            points="384.65 582.59 384.64 579.63 391 575.96 392.56 579.82 386.22 583.51 384.65 582.59"
            fill="#e1e1e1"
          />
          <path
            d="M391,585.05a2,2,0,0,1-.92,1.58c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C390.61,584.24,391,584.47,391,585.05Z"
            fill="#5c5c5c"
          />
          <path
            d="M394.09,583.28a2,2,0,0,1-.91,1.57c-.5.29-.91.06-.91-.52a2,2,0,0,1,.91-1.58C393.69,582.46,394.09,582.7,394.09,583.28Z"
            fill="#5c5c5c"
          />
          <polygon
            points="384.65 582.62 384.65 582.58 384.65 579.56 378.05 575.75 378.05 578.71 377.98 578.76 374.71 576.87 374.71 580.92 388.15 588.68 388.15 584.63 384.65 582.62"
            fill="#acacac"
          />
          <path
            d="M387,587.42a4.92,4.92,0,0,0-2.22-3.85,2.4,2.4,0,0,0-.86-.3h-.16a1.05,1.05,0,0,0-.4.07,1.63,1.63,0,0,0-1.15.94c0,.15.1.38.34.65a4.92,4.92,0,0,0,2.23,3.76,2.15,2.15,0,0,0,.81.29l.08.16a2,2,0,0,0,.67-.29A1.52,1.52,0,0,0,387,587.42Z"
            fill="url(#linear-gradient-76)"
          />
          <path
            d="M386.43,587.68a4.91,4.91,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.27A4.91,4.91,0,0,0,384.2,589C385.43,589.66,386.43,589.09,386.43,587.68Z"
            fill="url(#linear-gradient-77)"
          />
          <path
            d="M386.27,587.59A4.55,4.55,0,0,0,384.2,584c-1.14-.66-2.07-.13-2.07,1.19a4.57,4.57,0,0,0,2.07,3.57C385.35,589.43,386.27,588.9,386.27,587.59Z"
            fill="url(#linear-gradient-78)"
          />
          <path
            d="M385.34,587.05a2.5,2.5,0,0,0-1.14-2c-.63-.37-1.13-.08-1.13.64a2.52,2.52,0,0,0,1.13,2C384.83,588.06,385.34,587.77,385.34,587.05Z"
            fill="#c4c4c4"
          />
          <path
            d="M385,586.85a1.79,1.79,0,0,0-.8-1.38c-.44-.25-.8-.05-.8.46a1.77,1.77,0,0,0,.8,1.38C384.64,587.56,385,587.36,385,586.85Z"
            fill="#acacac"
          />
          <path
            d="M379.91,583.37a4.9,4.9,0,0,0-2.23-3.85,2.1,2.1,0,0,0-.85-.29l-.1,0h-.06a1.05,1.05,0,0,0-.4.07,1.63,1.63,0,0,0-1.15.94c0,.15.1.38.34.65a4.93,4.93,0,0,0,2.22,3.76,2.14,2.14,0,0,0,.82.29l.08.16a2.21,2.21,0,0,0,.67-.28A1.55,1.55,0,0,0,379.91,583.37Z"
            fill="url(#linear-gradient-79)"
          />
          <path
            d="M379.38,583.63a4.93,4.93,0,0,0-2.23-3.85c-1.23-.71-2.23-.14-2.23,1.27a4.91,4.91,0,0,0,2.23,3.85C378.38,585.61,379.38,585,379.38,583.63Z"
            fill="url(#linear-gradient-80)"
          />
          <path
            d="M379.22,583.54a4.53,4.53,0,0,0-2.07-3.57c-1.14-.67-2.07-.14-2.07,1.18a4.57,4.57,0,0,0,2.07,3.57C378.3,585.38,379.22,584.85,379.22,583.54Z"
            fill="url(#linear-gradient-81)"
          />
          <path
            d="M378.29,583a2.5,2.5,0,0,0-1.14-2c-.63-.37-1.14-.08-1.14.65a2.51,2.51,0,0,0,1.14,2C377.78,584,378.29,583.72,378.29,583Z"
            fill="#c4c4c4"
          />
          <path
            d="M378,582.8a1.79,1.79,0,0,0-.8-1.38c-.44-.25-.8,0-.8.46a1.77,1.77,0,0,0,.8,1.38C377.59,583.51,378,583.31,378,582.8Z"
            fill="#acacac"
          />
          <polygon
            points="384.65 582.58 384.65 579.56 386.22 583.51 384.65 582.58"
            fill="#dcdcdc"
          />
          <path
            d="M390.9,584.87a2,2,0,0,1-.91,1.58c-.51.29-.92,0-.92-.53a2,2,0,0,1,.92-1.57C390.49,584.06,390.9,584.29,390.9,584.87Z"
            fill="#fff"
          />
          <path
            d="M394,583.09a2,2,0,0,1-.91,1.58c-.51.29-.92.06-.92-.52a2,2,0,0,1,.92-1.58C393.57,582.28,394,582.51,394,583.09Z"
            fill="#fff"
          />
        </g>
        <g id="car-4">
          <g>
            <path
              d="M406.19,473.9a4.93,4.93,0,0,1,2.23-3.85,2.29,2.29,0,0,1,.85-.3h.16a1.14,1.14,0,0,1,.41.07,1.61,1.61,0,0,1,1.14.94c0,.15-.1.38-.33.65a5,5,0,0,1-2.23,3.76,2.25,2.25,0,0,1-.82.29l-.08.16a2,2,0,0,1-.67-.29A1.52,1.52,0,0,1,406.19,473.9Z"
              fill="url(#linear-gradient-82)"
            />
            <path
              d="M406.72,474.16a4.93,4.93,0,0,1,2.23-3.85c1.23-.71,2.23-.14,2.23,1.27a4.91,4.91,0,0,1-2.23,3.85C407.72,476.14,406.72,475.57,406.72,474.16Z"
              fill="url(#linear-gradient-83)"
            />
            <path
              d="M406.88,474.07a4.57,4.57,0,0,1,2.07-3.58c1.14-.66,2.07-.13,2.07,1.18a4.57,4.57,0,0,1-2.07,3.58C407.81,475.91,406.88,475.38,406.88,474.07Z"
              fill="url(#linear-gradient-84)"
            />
            <path
              d="M407.81,473.53a2.5,2.5,0,0,1,1.14-2c.63-.37,1.14-.08,1.14.64a2.52,2.52,0,0,1-1.14,2C408.32,474.54,407.81,474.25,407.81,473.53Z"
              fill="url(#linear-gradient-85)"
            />
            <path
              d="M408.15,473.33A1.79,1.79,0,0,1,409,472c.44-.25.8-.05.8.46a1.77,1.77,0,0,1-.8,1.38C408.51,474,408.15,473.84,408.15,473.33Z"
              fill="url(#linear-gradient-86)"
            />
          </g>
          <g>
            <path
              d="M413.24,469.85a4.93,4.93,0,0,1,2.23-3.85,2.29,2.29,0,0,1,.85-.3h.16a1.1,1.1,0,0,1,.41.07,1.61,1.61,0,0,1,1.14.94c.05.15-.1.38-.33.65a5,5,0,0,1-2.23,3.76,2.27,2.27,0,0,1-.81.29l-.09.16a2,2,0,0,1-.67-.29A1.52,1.52,0,0,1,413.24,469.85Z"
              fill="url(#linear-gradient-87)"
            />
            <path
              d="M413.77,470.11a4.93,4.93,0,0,1,2.23-3.85c1.23-.71,2.23-.14,2.23,1.27a4.91,4.91,0,0,1-2.23,3.85C414.77,472.09,413.77,471.52,413.77,470.11Z"
              fill="url(#linear-gradient-88)"
            />
            <path
              d="M413.93,470a4.59,4.59,0,0,1,2.07-3.58c1.15-.66,2.07-.13,2.07,1.19A4.55,4.55,0,0,1,416,471.2C414.86,471.86,413.93,471.33,413.93,470Z"
              fill="url(#linear-gradient-89)"
            />
            <path
              d="M414.86,469.48a2.5,2.5,0,0,1,1.14-2c.63-.37,1.14-.08,1.14.64a2.53,2.53,0,0,1-1.14,2C415.37,470.49,414.86,470.2,414.86,469.48Z"
              fill="url(#linear-gradient-90)"
            />
            <path
              d="M415.2,469.28a1.79,1.79,0,0,1,.8-1.38c.44-.25.8,0,.8.46a1.77,1.77,0,0,1-.8,1.38C415.56,470,415.2,469.79,415.2,469.28Z"
              fill="url(#linear-gradient-91)"
            />
          </g>
          <polygon
            points="406.58 476.67 406.58 476.67 406.58 476.67 406.58 472.62 420.02 464.86 420.02 468.91 426.38 472.58 412.93 480.34 406.58 476.67 406.58 476.67 406.58 476.67"
            fill="#898989"
          />
          <polygon
            points="420.02 464.86 406.58 472.62 412.93 476.29 426.38 468.53 420.02 464.86"
            fill="#c4c4c4"
          />
          <polygon
            points="416.44 471.28 423.04 467.47 416.69 463.8 410.08 467.62 416.44 471.28"
            fill="#c4c4c4"
          />
          <polygon
            points="416.43 474.24 416.44 471.28 410.08 467.62 408.53 471.48 414.87 475.17 416.43 474.24"
            fill="#e1e1e1"
          />
          <path
            d="M410.07,476.71a2,2,0,0,0,.91,1.57c.51.29.92.06.92-.52a2,2,0,0,0-.92-1.58C410.48,475.89,410.07,476.13,410.07,476.71Z"
            fill="#5c5c5c"
          />
          <path
            d="M407,474.93a2,2,0,0,0,.91,1.58c.51.29.92.05.92-.53a2,2,0,0,0-.92-1.57C407.4,474.12,407,474.35,407,474.93Z"
            fill="#5c5c5c"
          />
          <polygon
            points="416.43 474.27 416.43 474.23 416.43 471.22 423.04 467.4 423.04 470.37 423.11 470.41 426.38 468.53 426.38 472.58 412.93 480.34 412.93 476.29 416.43 474.27"
            fill="#acacac"
          />
          <path
            d="M414.12,479.07a4.91,4.91,0,0,1,2.23-3.84,2,2,0,0,1,.85-.3h.16a1.35,1.35,0,0,1,.41.07,1.64,1.64,0,0,1,1.14.94c0,.15-.1.39-.33.65a4.91,4.91,0,0,1-2.23,3.76,2.31,2.31,0,0,1-.81.3l-.08.15a2,2,0,0,1-.68-.28A1.55,1.55,0,0,1,414.12,479.07Z"
            fill="url(#linear-gradient-92)"
          />
          <path
            d="M414.65,479.33a4.91,4.91,0,0,1,2.23-3.84c1.23-.71,2.23-.15,2.23,1.27a4.91,4.91,0,0,1-2.23,3.84C415.65,481.31,414.65,480.74,414.65,479.33Z"
            fill="url(#linear-gradient-93)"
          />
          <path
            d="M414.81,479.24a4.57,4.57,0,0,1,2.07-3.57c1.15-.66,2.07-.13,2.07,1.18a4.55,4.55,0,0,1-2.07,3.57C415.74,481.08,414.81,480.55,414.81,479.24Z"
            fill="url(#linear-gradient-94)"
          />
          <path
            d="M415.75,478.7a2.49,2.49,0,0,1,1.13-2c.63-.36,1.14-.07,1.14.65a2.5,2.5,0,0,1-1.14,2C416.25,479.71,415.75,479.42,415.75,478.7Z"
            fill="#c4c4c4"
          />
          <path
            d="M416.08,478.51a1.76,1.76,0,0,1,.8-1.38c.44-.26.8-.05.8.45a1.76,1.76,0,0,1-.8,1.38C416.44,479.22,416.08,479,416.08,478.51Z"
            fill="#acacac"
          />
          <path
            d="M421.17,475a4.91,4.91,0,0,1,2.23-3.84,2,2,0,0,1,.86-.3h.15a1.35,1.35,0,0,1,.41.07,1.64,1.64,0,0,1,1.14.94c.05.15-.1.39-.33.65a4.91,4.91,0,0,1-2.23,3.76,2.31,2.31,0,0,1-.81.3l-.08.15a2,2,0,0,1-.68-.28A1.55,1.55,0,0,1,421.17,475Z"
            fill="url(#linear-gradient-95)"
          />
          <path
            d="M421.7,475.28a4.91,4.91,0,0,1,2.23-3.84c1.23-.71,2.23-.14,2.23,1.27a4.91,4.91,0,0,1-2.23,3.84C422.7,477.26,421.7,476.69,421.7,475.28Z"
            fill="url(#linear-gradient-96)"
          />
          <path
            d="M421.86,475.19a4.57,4.57,0,0,1,2.07-3.57c1.15-.66,2.07-.13,2.07,1.18a4.55,4.55,0,0,1-2.07,3.57C422.79,477,421.86,476.5,421.86,475.19Z"
            fill="url(#linear-gradient-97)"
          />
          <path
            d="M422.8,474.65a2.51,2.51,0,0,1,1.13-2c.63-.36,1.14-.07,1.14.65a2.5,2.5,0,0,1-1.14,2C423.31,475.66,422.8,475.37,422.8,474.65Z"
            fill="#c4c4c4"
          />
          <path
            d="M423.13,474.46a1.76,1.76,0,0,1,.8-1.38c.44-.26.8-.05.8.45a1.76,1.76,0,0,1-.8,1.38C423.49,475.17,423.13,475,423.13,474.46Z"
            fill="#acacac"
          />
          <polygon
            points="416.43 474.23 416.43 471.22 414.87 475.17 416.43 474.23"
            fill="#dcdcdc"
          />
          <path
            d="M410.18,476.52a2,2,0,0,0,.92,1.58c.5.29.91.06.91-.52a2,2,0,0,0-.91-1.58C410.59,475.71,410.18,475.94,410.18,476.52Z"
            fill="#fff"
          />
          <path
            d="M407.1,474.75a2,2,0,0,0,.92,1.57c.5.29.91.06.91-.52a2,2,0,0,0-.91-1.57C407.51,473.93,407.1,474.17,407.1,474.75Z"
            fill="#fff"
          />
        </g>
        <g id="car-3">
          <g>
            <path
              d="M517.17,433.13a4.93,4.93,0,0,1,2.23-3.85,2.09,2.09,0,0,1,.86-.29l.09,0h.06a1.1,1.1,0,0,1,.41.07A1.61,1.61,0,0,1,522,430c0,.15-.1.38-.33.65a4.94,4.94,0,0,1-2.23,3.76,2.1,2.1,0,0,1-.81.29l-.08.16a2.18,2.18,0,0,1-.68-.28A1.55,1.55,0,0,1,517.17,433.13Z"
              fill="url(#linear-gradient-98)"
            />
            <path
              d="M517.7,433.39a4.93,4.93,0,0,1,2.23-3.85c1.23-.71,2.23-.14,2.23,1.27a4.91,4.91,0,0,1-2.23,3.85C518.7,435.37,517.7,434.8,517.7,433.39Z"
              fill="url(#linear-gradient-99)"
            />
            <path
              d="M517.86,433.3a4.57,4.57,0,0,1,2.07-3.58c1.15-.66,2.07-.13,2.07,1.19a4.55,4.55,0,0,1-2.07,3.57C518.79,435.14,517.86,434.61,517.86,433.3Z"
              fill="url(#linear-gradient-100)"
            />
            <path
              d="M518.8,432.76a2.49,2.49,0,0,1,1.13-2c.63-.37,1.14-.08,1.14.64a2.52,2.52,0,0,1-1.14,2C519.3,433.77,518.8,433.48,518.8,432.76Z"
              fill="url(#linear-gradient-101)"
            />
            <path
              d="M519.13,432.56a1.79,1.79,0,0,1,.8-1.38c.44-.25.8-.05.8.46a1.77,1.77,0,0,1-.8,1.38C519.49,433.27,519.13,433.07,519.13,432.56Z"
              fill="url(#linear-gradient-102)"
            />
          </g>
          <g>
            <path
              d="M524.22,429.08a4.93,4.93,0,0,1,2.23-3.85,2.28,2.28,0,0,1,.86-.3h.15a1.1,1.1,0,0,1,.41.07,1.64,1.64,0,0,1,1.14.94c0,.15-.1.38-.33.65a4.94,4.94,0,0,1-2.23,3.76,2.27,2.27,0,0,1-.81.29l-.08.16a2,2,0,0,1-.68-.29A1.52,1.52,0,0,1,524.22,429.08Z"
              fill="url(#linear-gradient-103)"
            />
            <path
              d="M524.75,429.34a4.93,4.93,0,0,1,2.23-3.85c1.23-.71,2.23-.14,2.23,1.28a4.91,4.91,0,0,1-2.23,3.84C525.75,431.32,524.75,430.75,524.75,429.34Z"
              fill="url(#linear-gradient-104)"
            />
            <path
              d="M524.91,429.25a4.61,4.61,0,0,1,2.07-3.58c1.15-.66,2.07-.13,2.07,1.19a4.55,4.55,0,0,1-2.07,3.57C525.84,431.09,524.91,430.56,524.91,429.25Z"
              fill="url(#linear-gradient-105)"
            />
            <path
              d="M525.85,428.71a2.51,2.51,0,0,1,1.13-2c.63-.37,1.14-.07,1.14.65a2.51,2.51,0,0,1-1.14,2C526.36,429.72,525.85,429.43,525.85,428.71Z"
              fill="url(#linear-gradient-106)"
            />
            <path
              d="M526.18,428.51a1.79,1.79,0,0,1,.8-1.38c.44-.25.8-.05.8.46A1.77,1.77,0,0,1,527,429C526.54,429.22,526.18,429,526.18,428.51Z"
              fill="url(#linear-gradient-107)"
            />
          </g>
          <polygon
            points="517.56 435.9 517.56 435.9 517.56 435.9 517.56 431.85 531 424.09 531 428.14 537.36 431.81 523.91 439.57 517.56 435.9 517.56 435.9 517.56 435.9"
            fill="#898989"
          />
          <polygon
            points="531 424.09 517.56 431.85 523.91 435.52 537.36 427.76 531 424.09"
            fill="#c4c4c4"
          />
          <polygon
            points="527.42 430.51 534.02 426.7 527.67 423.03 521.07 426.85 527.42 430.51"
            fill="#c4c4c4"
          />
          <polygon
            points="527.42 433.47 527.42 430.51 521.07 426.85 519.51 430.71 525.85 434.4 527.42 433.47"
            fill="#e1e1e1"
          />
          <path
            d="M521.05,435.94a2,2,0,0,0,.91,1.57c.51.29.92.06.92-.52a2,2,0,0,0-.92-1.58C521.46,435.12,521.05,435.36,521.05,435.94Z"
            fill="#5c5c5c"
          />
          <path
            d="M518,434.16a2,2,0,0,0,.92,1.58c.5.29.91.06.91-.53a2,2,0,0,0-.91-1.57C518.38,433.35,518,433.58,518,434.16Z"
            fill="#5c5c5c"
          />
          <polygon
            points="527.41 433.5 527.42 433.46 527.42 430.45 534.02 426.63 534.02 429.6 534.09 429.64 537.36 427.76 537.36 431.81 523.91 439.57 523.91 435.52 527.41 433.5"
            fill="#acacac"
          />
          <path
            d="M525.1,438.3a4.91,4.91,0,0,1,2.23-3.84,2.17,2.17,0,0,1,.86-.3h.15a1.3,1.3,0,0,1,.41.07,1.68,1.68,0,0,1,1.15.94c0,.15-.11.39-.34.65a4.91,4.91,0,0,1-2.23,3.76,2.31,2.31,0,0,1-.81.3l-.08.15a2.14,2.14,0,0,1-.68-.28A1.57,1.57,0,0,1,525.1,438.3Z"
            fill="url(#linear-gradient-108)"
          />
          <path
            d="M525.63,438.56a4.91,4.91,0,0,1,2.23-3.84c1.24-.71,2.23-.14,2.23,1.27a4.88,4.88,0,0,1-2.23,3.84C526.63,440.54,525.63,440,525.63,438.56Z"
            fill="url(#linear-gradient-109)"
          />
          <path
            d="M525.79,438.47a4.57,4.57,0,0,1,2.07-3.57c1.15-.66,2.08-.13,2.08,1.18a4.56,4.56,0,0,1-2.08,3.57C526.72,440.31,525.79,439.78,525.79,438.47Z"
            fill="url(#linear-gradient-110)"
          />
          <path
            d="M526.73,437.93a2.51,2.51,0,0,1,1.13-2c.63-.36,1.14-.07,1.14.65a2.5,2.5,0,0,1-1.14,2C527.24,438.94,526.73,438.65,526.73,437.93Z"
            fill="#c4c4c4"
          />
          <path
            d="M527.06,437.74a1.76,1.76,0,0,1,.8-1.38c.45-.26.8-.05.8.45a1.73,1.73,0,0,1-.8,1.38C527.42,438.45,527.06,438.24,527.06,437.74Z"
            fill="#acacac"
          />
          <path
            d="M532.15,434.25a4.91,4.91,0,0,1,2.23-3.84,2,2,0,0,1,.86-.3h.15a1.3,1.3,0,0,1,.41.07,1.68,1.68,0,0,1,1.15.94c0,.15-.11.39-.34.65a4.91,4.91,0,0,1-2.23,3.76,2.13,2.13,0,0,1-.81.3l-.08.15a2.14,2.14,0,0,1-.68-.28A1.57,1.57,0,0,1,532.15,434.25Z"
            fill="url(#linear-gradient-111)"
          />
          <path
            d="M532.69,434.51a4.9,4.9,0,0,1,2.22-3.84c1.24-.71,2.23-.14,2.23,1.27a4.88,4.88,0,0,1-2.23,3.84C533.68,436.49,532.69,435.93,532.69,434.51Z"
            fill="url(#linear-gradient-112)"
          />
          <path
            d="M532.84,434.42a4.57,4.57,0,0,1,2.07-3.57c1.15-.66,2.08-.13,2.08,1.18a4.56,4.56,0,0,1-2.08,3.57C533.77,436.26,532.84,435.74,532.84,434.42Z"
            fill="url(#linear-gradient-113)"
          />
          <path
            d="M533.78,433.88a2.51,2.51,0,0,1,1.13-2c.63-.36,1.14-.07,1.14.65a2.5,2.5,0,0,1-1.14,2C534.29,434.89,533.78,434.6,533.78,433.88Z"
            fill="#c4c4c4"
          />
          <path
            d="M534.11,433.69a1.76,1.76,0,0,1,.8-1.38c.45-.26.8-.05.8.45a1.73,1.73,0,0,1-.8,1.38C534.47,434.4,534.11,434.19,534.11,433.69Z"
            fill="#acacac"
          />
          <polygon points="527.42 433.46 527.42 430.45 525.85 434.4 527.42 433.46" fill="#dcdcdc" />
          <path
            d="M521.16,435.75a2,2,0,0,0,.92,1.58c.5.29.91.06.91-.52a2,2,0,0,0-.91-1.58C521.57,434.94,521.16,435.17,521.16,435.75Z"
            fill="#fff"
          />
          <path
            d="M518.09,434a2,2,0,0,0,.91,1.57c.51.3.91.06.91-.52a2,2,0,0,0-.91-1.57C518.49,433.16,518.09,433.4,518.09,434Z"
            fill="#fff"
          />
        </g>
        <g id="car-8">
          <g>
            <path
              d="M89.17,359.37a4.93,4.93,0,0,1,2.23-3.85,2.29,2.29,0,0,1,.85-.3h.16a1.05,1.05,0,0,1,.4.07,1.63,1.63,0,0,1,1.15.94c0,.15-.1.38-.34.65a4.93,4.93,0,0,1-2.22,3.76,2.25,2.25,0,0,1-.82.29l-.08.16a2,2,0,0,1-.67-.29A1.52,1.52,0,0,1,89.17,359.37Z"
              fill="url(#linear-gradient-114)"
            />
            <path
              d="M89.7,359.63a4.91,4.91,0,0,1,2.23-3.85c1.23-.71,2.23-.14,2.23,1.27a4.91,4.91,0,0,1-2.23,3.85C90.7,361.61,89.7,361,89.7,359.63Z"
              fill="url(#linear-gradient-115)"
            />
            <path
              d="M89.86,359.54A4.55,4.55,0,0,1,91.93,356c1.14-.66,2.07-.13,2.07,1.18a4.57,4.57,0,0,1-2.07,3.58C90.78,361.38,89.86,360.85,89.86,359.54Z"
              fill="url(#linear-gradient-116)"
            />
            <path
              d="M90.79,359a2.5,2.5,0,0,1,1.14-2c.63-.37,1.14-.08,1.14.64a2.52,2.52,0,0,1-1.14,2C91.3,360,90.79,359.72,90.79,359Z"
              fill="url(#linear-gradient-117)"
            />
            <path
              d="M91.13,358.8a1.77,1.77,0,0,1,.8-1.38c.44-.25.8-.05.8.46a1.79,1.79,0,0,1-.8,1.38C91.49,359.51,91.13,359.31,91.13,358.8Z"
              fill="url(#linear-gradient-118)"
            />
          </g>
          <g>
            <path
              d="M96.22,355.32a4.91,4.91,0,0,1,2.23-3.85,2.29,2.29,0,0,1,.85-.3h.16a1.14,1.14,0,0,1,.41.07,1.61,1.61,0,0,1,1.14.94c0,.15-.1.38-.34.65a4.93,4.93,0,0,1-2.22,3.76,2.25,2.25,0,0,1-.82.29l-.08.16a2,2,0,0,1-.67-.29A1.52,1.52,0,0,1,96.22,355.32Z"
              fill="url(#linear-gradient-119)"
            />
            <path
              d="M96.75,355.58A4.93,4.93,0,0,1,99,351.73c1.23-.71,2.23-.14,2.23,1.27A4.91,4.91,0,0,1,99,356.85C97.75,357.56,96.75,357,96.75,355.58Z"
              fill="url(#linear-gradient-120)"
            />
            <path
              d="M96.91,355.49A4.57,4.57,0,0,1,99,351.91c1.14-.66,2.07-.13,2.07,1.18A4.57,4.57,0,0,1,99,356.67C97.84,357.33,96.91,356.8,96.91,355.49Z"
              fill="url(#linear-gradient-121)"
            />
            <path
              d="M97.84,355A2.5,2.5,0,0,1,99,353c.63-.37,1.14-.08,1.14.64a2.53,2.53,0,0,1-1.14,2C98.35,356,97.84,355.67,97.84,355Z"
              fill="url(#linear-gradient-122)"
            />
            <path
              d="M98.18,354.75a1.79,1.79,0,0,1,.8-1.38c.44-.25.8-.05.8.46a1.77,1.77,0,0,1-.8,1.38C98.54,355.46,98.18,355.26,98.18,354.75Z"
              fill="url(#linear-gradient-123)"
            />
          </g>
          <polygon
            points="89.56 362.14 89.56 362.14 89.56 362.14 89.56 358.09 103 350.33 103 354.38 109.35 358.05 95.91 365.81 89.56 362.14 89.56 362.14 89.56 362.14"
            fill="#898989"
          />
          <polygon
            points="103 350.33 89.56 358.09 95.91 361.76 109.35 354 103 350.33"
            fill="#c4c4c4"
          />
          <polygon
            points="99.42 356.75 106.02 352.94 99.67 349.27 93.06 353.08 99.42 356.75"
            fill="#c4c4c4"
          />
          <polygon
            points="99.41 359.71 99.42 356.75 93.06 353.08 91.51 356.95 97.84 360.64 99.41 359.71"
            fill="#e1e1e1"
          />
          <path
            d="M93.05,362.18a2,2,0,0,0,.91,1.57c.5.29.91.06.91-.52a2,2,0,0,0-.91-1.58C93.46,361.36,93.05,361.6,93.05,362.18Z"
            fill="#5c5c5c"
          />
          <path
            d="M90,360.4a2,2,0,0,0,.91,1.58c.51.29.92,0,.92-.53a2,2,0,0,0-.92-1.57C90.38,359.58,90,359.82,90,360.4Z"
            fill="#5c5c5c"
          />
          <polygon
            points="99.41 359.74 99.41 359.7 99.41 356.69 106.02 352.87 106.02 355.83 106.09 355.88 109.35 354 109.35 358.05 95.91 365.81 95.91 361.76 99.41 359.74"
            fill="#acacac"
          />
          <path
            d="M97.1,364.54a4.91,4.91,0,0,1,2.23-3.84,2,2,0,0,1,.85-.3h.16a1.41,1.41,0,0,1,.41.07,1.64,1.64,0,0,1,1.14.94c0,.15-.1.39-.33.65a4.91,4.91,0,0,1-2.23,3.76,2.31,2.31,0,0,1-.81.3l-.09.15a2,2,0,0,1-.67-.28A1.55,1.55,0,0,1,97.1,364.54Z"
            fill="url(#linear-gradient-124)"
          />
          <path
            d="M97.63,364.8A4.91,4.91,0,0,1,99.86,361c1.23-.72,2.23-.15,2.23,1.27a4.91,4.91,0,0,1-2.23,3.84C98.63,366.78,97.63,366.21,97.63,364.8Z"
            fill="url(#linear-gradient-125)"
          />
          <path
            d="M97.79,364.71a4.57,4.57,0,0,1,2.07-3.57c1.15-.66,2.07-.13,2.07,1.18a4.55,4.55,0,0,1-2.07,3.57C98.72,366.55,97.79,366,97.79,364.71Z"
            fill="url(#linear-gradient-126)"
          />
          <path
            d="M98.72,364.17a2.5,2.5,0,0,1,1.14-2c.63-.36,1.14-.07,1.14.65a2.5,2.5,0,0,1-1.14,2C99.23,365.18,98.72,364.89,98.72,364.17Z"
            fill="#c4c4c4"
          />
          <path
            d="M99.06,364a1.76,1.76,0,0,1,.8-1.38c.44-.26.8-.05.8.45a1.76,1.76,0,0,1-.8,1.38C99.42,364.69,99.06,364.48,99.06,364Z"
            fill="#acacac"
          />
          <path
            d="M104.15,360.49a4.91,4.91,0,0,1,2.23-3.84,2,2,0,0,1,.85-.3h.16a1.35,1.35,0,0,1,.41.07,1.64,1.64,0,0,1,1.14.94c0,.15-.1.39-.33.65a4.93,4.93,0,0,1-2.23,3.76,2.31,2.31,0,0,1-.81.3l-.09.15a2,2,0,0,1-.67-.28A1.55,1.55,0,0,1,104.15,360.49Z"
            fill="url(#linear-gradient-127)"
          />
          <path
            d="M104.68,360.75a4.91,4.91,0,0,1,2.23-3.84c1.23-.72,2.23-.14,2.23,1.27a4.91,4.91,0,0,1-2.23,3.84C105.68,362.73,104.68,362.16,104.68,360.75Z"
            fill="url(#linear-gradient-128)"
          />
          <path
            d="M104.84,360.66a4.57,4.57,0,0,1,2.07-3.57c1.15-.66,2.07-.13,2.07,1.18a4.55,4.55,0,0,1-2.07,3.57C105.77,362.5,104.84,362,104.84,360.66Z"
            fill="url(#linear-gradient-129)"
          />
          <path
            d="M105.77,360.12a2.5,2.5,0,0,1,1.14-2c.63-.36,1.14-.07,1.14.65a2.5,2.5,0,0,1-1.14,2C106.28,361.13,105.77,360.84,105.77,360.12Z"
            fill="#c4c4c4"
          />
          <path
            d="M106.11,359.93a1.76,1.76,0,0,1,.8-1.38c.44-.26.8-.05.8.45a1.76,1.76,0,0,1-.8,1.38C106.47,360.64,106.11,360.43,106.11,359.93Z"
            fill="#acacac"
          />
          <polygon points="99.41 359.7 99.41 356.69 97.84 360.64 99.41 359.7" fill="#dcdcdc" />
          <path
            d="M93.16,362a2,2,0,0,0,.91,1.58c.51.29.92.06.92-.52a2,2,0,0,0-.92-1.58C93.57,361.18,93.16,361.41,93.16,362Z"
            fill="#fff"
          />
          <path
            d="M90.08,360.22a2,2,0,0,0,.92,1.57c.5.29.91.06.91-.52a2,2,0,0,0-.91-1.58C90.49,359.4,90.08,359.64,90.08,360.22Z"
            fill="#fff"
          />
        </g>
        <g id="car-9">
          <g>
            <path
              d="M291.94,118a4.91,4.91,0,0,1,2.23-3.84,2,2,0,0,1,.86-.3.14.14,0,0,1,.09,0h.06a1.3,1.3,0,0,1,.41.07,1.68,1.68,0,0,1,1.15.94c0,.15-.11.39-.34.65a4.93,4.93,0,0,1-2.23,3.76,2.24,2.24,0,0,1-.81.3l-.08.15a2.4,2.4,0,0,1-.68-.28A1.57,1.57,0,0,1,291.94,118Z"
              fill="url(#linear-gradient-130)"
            />
            <path
              d="M292.48,118.23a4.88,4.88,0,0,1,2.23-3.84c1.23-.71,2.22-.15,2.22,1.27a4.9,4.9,0,0,1-2.22,3.84C293.47,120.21,292.48,119.64,292.48,118.23Z"
              fill="url(#linear-gradient-131)"
            />
            <path
              d="M292.63,118.14a4.56,4.56,0,0,1,2.08-3.57c1.14-.66,2.07-.13,2.07,1.18a4.57,4.57,0,0,1-2.07,3.57C293.56,120,292.63,119.45,292.63,118.14Z"
              fill="url(#linear-gradient-132)"
            />
            <path
              d="M293.57,117.6a2.5,2.5,0,0,1,1.14-2c.62-.36,1.13-.07,1.13.65a2.51,2.51,0,0,1-1.13,2C294.08,118.61,293.57,118.32,293.57,117.6Z"
              fill="url(#linear-gradient-133)"
            />
            <path
              d="M293.91,117.41a1.73,1.73,0,0,1,.8-1.38c.44-.26.79,0,.79.45a1.75,1.75,0,0,1-.79,1.38C294.26,118.12,293.91,117.91,293.91,117.41Z"
              fill="url(#linear-gradient-134)"
            />
          </g>
          <g>
            <path
              d="M299,113.92a4.91,4.91,0,0,1,2.23-3.84,2,2,0,0,1,.86-.3h.15a1.3,1.3,0,0,1,.41.07,1.66,1.66,0,0,1,1.15.94c0,.15-.11.39-.34.65a4.93,4.93,0,0,1-2.23,3.76,2.24,2.24,0,0,1-.81.3l-.08.15a2.21,2.21,0,0,1-.67-.28A1.55,1.55,0,0,1,299,113.92Z"
              fill="url(#linear-gradient-135)"
            />
            <path
              d="M299.53,114.18a4.88,4.88,0,0,1,2.23-3.84c1.23-.71,2.23-.14,2.23,1.27a4.91,4.91,0,0,1-2.23,3.84C300.52,116.16,299.53,115.59,299.53,114.18Z"
              fill="url(#linear-gradient-136)"
            />
            <path
              d="M299.68,114.09a4.56,4.56,0,0,1,2.08-3.57c1.14-.66,2.07-.13,2.07,1.18a4.57,4.57,0,0,1-2.07,3.57C300.61,115.93,299.68,115.4,299.68,114.09Z"
              fill="url(#linear-gradient-137)"
            />
            <path
              d="M300.62,113.55a2.5,2.5,0,0,1,1.14-2c.62-.36,1.13-.07,1.13.65a2.51,2.51,0,0,1-1.13,2C301.13,114.56,300.62,114.27,300.62,113.55Z"
              fill="url(#linear-gradient-138)"
            />
            <path
              d="M301,113.36a1.73,1.73,0,0,1,.8-1.38c.44-.26.8,0,.8.45a1.76,1.76,0,0,1-.8,1.38C301.31,114.07,301,113.86,301,113.36Z"
              fill="url(#linear-gradient-139)"
            />
          </g>
          <polygon
            points="292.33 120.74 292.33 120.74 292.33 120.74 292.33 116.69 305.77 108.93 305.77 112.98 312.13 116.65 298.69 124.41 292.33 120.74 292.33 120.74 292.33 120.74"
            fill="#898989"
          />
          <polygon
            points="305.77 108.93 292.33 116.69 298.69 120.36 312.13 112.6 305.77 108.93"
            fill="#c4c4c4"
          />
          <polygon
            points="302.19 115.36 308.8 111.55 302.44 107.88 295.84 111.69 302.19 115.36"
            fill="#c4c4c4"
          />
          <polygon
            points="302.19 118.31 302.19 115.36 295.84 111.69 294.28 115.55 300.62 119.24 302.19 118.31"
            fill="#e1e1e1"
          />
          <path
            d="M295.82,120.78a2,2,0,0,0,.92,1.58c.5.29.91,0,.91-.53a2,2,0,0,0-.91-1.57C296.23,120,295.82,120.2,295.82,120.78Z"
            fill="#5c5c5c"
          />
          <path
            d="M292.74,119a2,2,0,0,0,.92,1.58c.5.29.91.06.91-.52a2,2,0,0,0-.91-1.58C293.15,118.19,292.74,118.42,292.74,119Z"
            fill="#5c5c5c"
          />
          <polygon
            points="302.18 118.34 302.19 118.3 302.19 115.29 308.79 111.48 308.79 114.44 308.86 114.49 312.13 112.6 312.13 116.65 298.69 124.41 298.69 120.36 302.18 118.34"
            fill="#acacac"
          />
          <path
            d="M299.87,123.14a4.91,4.91,0,0,1,2.23-3.84,2.22,2.22,0,0,1,.86-.3h.16a1.29,1.29,0,0,1,.4.07,1.68,1.68,0,0,1,1.15.94c0,.15-.11.39-.34.65a5,5,0,0,1-2.23,3.77,2.21,2.21,0,0,1-.81.29l-.08.16a2,2,0,0,1-.67-.29A1.54,1.54,0,0,1,299.87,123.14Z"
            fill="url(#linear-gradient-140)"
          />
          <path
            d="M300.41,123.41a4.91,4.91,0,0,1,2.23-3.85c1.23-.71,2.23-.14,2.23,1.27a4.94,4.94,0,0,1-2.23,3.85C301.41,125.39,300.41,124.82,300.41,123.41Z"
            fill="url(#linear-gradient-141)"
          />
          <path
            d="M300.57,123.31a4.55,4.55,0,0,1,2.07-3.57c1.14-.66,2.07-.13,2.07,1.18a4.56,4.56,0,0,1-2.07,3.57C301.49,125.16,300.57,124.63,300.57,123.31Z"
            fill="url(#linear-gradient-142)"
          />
          <path
            d="M301.5,122.77a2.51,2.51,0,0,1,1.14-2c.62-.36,1.13-.07,1.13.65a2.51,2.51,0,0,1-1.13,2C302,123.79,301.5,123.49,301.5,122.77Z"
            fill="#c4c4c4"
          />
          <path
            d="M301.84,122.58a1.77,1.77,0,0,1,.8-1.38c.44-.25.8,0,.8.46a1.79,1.79,0,0,1-.8,1.38C302.2,123.29,301.84,123.09,301.84,122.58Z"
            fill="#acacac"
          />
          <path
            d="M306.92,119.1a4.91,4.91,0,0,1,2.23-3.85,2.4,2.4,0,0,1,.86-.3h.16a1.29,1.29,0,0,1,.4.08,1.63,1.63,0,0,1,1.15.94c0,.14-.1.38-.34.64a4.92,4.92,0,0,1-2.23,3.77,2.21,2.21,0,0,1-.81.29l-.08.16a2,2,0,0,1-.67-.29A1.53,1.53,0,0,1,306.92,119.1Z"
            fill="url(#linear-gradient-143)"
          />
          <path
            d="M307.46,119.36a4.91,4.91,0,0,1,2.23-3.85c1.23-.71,2.23-.14,2.23,1.27a4.93,4.93,0,0,1-2.23,3.85C308.46,121.34,307.46,120.77,307.46,119.36Z"
            fill="url(#linear-gradient-144)"
          />
          <path
            d="M307.62,119.26a4.55,4.55,0,0,1,2.07-3.57c1.14-.66,2.07-.13,2.07,1.18a4.59,4.59,0,0,1-2.07,3.58C308.54,121.11,307.62,120.58,307.62,119.26Z"
            fill="url(#linear-gradient-145)"
          />
          <path
            d="M308.55,118.72a2.51,2.51,0,0,1,1.14-2c.63-.36,1.13-.07,1.13.65a2.49,2.49,0,0,1-1.13,2C309.06,119.74,308.55,119.44,308.55,118.72Z"
            fill="#c4c4c4"
          />
          <path
            d="M308.89,118.53a1.77,1.77,0,0,1,.8-1.38c.44-.25.8-.05.8.46a1.79,1.79,0,0,1-.8,1.38C309.25,119.24,308.89,119,308.89,118.53Z"
            fill="#acacac"
          />
          <polygon points="302.19 118.3 302.19 115.29 300.62 119.24 302.19 118.3" fill="#dcdcdc" />
          <path
            d="M295.94,120.6a2,2,0,0,0,.91,1.57c.51.3.91.06.91-.52a2,2,0,0,0-.91-1.58C296.35,119.78,295.94,120,295.94,120.6Z"
            fill="#fff"
          />
          <path
            d="M292.86,118.82a2,2,0,0,0,.91,1.58c.51.29.92,0,.92-.53a2,2,0,0,0-.92-1.57C293.27,118,292.86,118.24,292.86,118.82Z"
            fill="#fff"
          />
        </g>
        <polygon
          points="97.74 437.41 70.47 421.66 43.36 437.31 36.02 437.3 36.02 561.7 70.68 581.72 105.13 561.83 105.13 437.42 97.74 437.41"
          fill="#acacac"
        />
        <polygon
          points="70.68 457.31 36.02 437.3 70.47 417.41 105.13 437.42 70.68 457.31"
          fill="#ccc"
        />
        <polygon
          points="70.68 581.72 70.68 457.31 105.13 437.42 105.13 561.83 70.68 581.72"
          fill="#898989"
        />
        <polygon
          points="91.2 434.12 70.49 422.17 49.92 434.05 44.34 434.04 44.34 437.27 70.66 452.46 96.81 437.36 96.81 434.13 91.2 434.12"
          fill="#acacac"
        />
        <polygon
          points="70.66 449.23 44.34 434.04 70.49 418.94 96.81 434.13 70.66 449.23"
          fill="#c4c4c4"
        />
        <polygon
          points="70.66 452.46 70.66 449.23 96.81 434.13 96.81 437.36 70.66 452.46"
          fill="#898989"
        />
        <polygon
          points="267.65 622.28 214.62 591.66 161.92 622.09 147.63 622.06 147.63 630.33 215.04 669.25 282.02 630.58 282.02 622.3 267.65 622.28"
          fill="#bababa"
        />
        <polygon
          points="215.04 660.98 147.63 622.06 214.62 583.39 282.02 622.3 215.04 660.98"
          fill="#d5d5d5"
        />
        <polygon
          points="215.04 669.25 215.04 660.98 282.02 622.3 282.02 630.58 215.04 669.25"
          fill="#9a9a9a"
        />
        <polygon
          points="254.93 615.88 214.67 592.64 174.66 615.74 163.82 615.72 163.82 622 214.99 651.54 265.84 622.18 265.84 615.9 254.93 615.88"
          fill="#939393"
        />
        <polygon
          points="214.99 645.26 163.82 615.72 214.67 586.36 265.84 615.9 214.99 645.26"
          fill="#989898"
        />
        <polygon
          points="214.99 651.54 214.99 645.26 265.84 615.9 265.84 622.18 214.99 651.54"
          fill="#7e7e7e"
        />
        <polygon
          points="267.65 601.11 214.62 570.49 161.92 600.91 147.63 600.89 147.63 609.16 215.04 648.08 282.02 609.4 282.02 601.13 267.65 601.11"
          fill="#bababa"
        />
        <polygon
          points="215.04 639.8 147.63 600.89 214.62 562.22 282.02 601.13 215.04 639.8"
          fill="#d5d5d5"
        />
        <polygon
          points="215.04 648.08 215.04 639.8 282.02 601.13 282.02 609.4 215.04 648.08"
          fill="#9a9a9a"
        />
        <polygon
          points="254.93 594.71 214.67 571.47 174.66 594.57 163.82 594.55 163.82 600.83 214.99 630.37 265.84 601.01 265.84 594.73 254.93 594.71"
          fill="#939393"
        />
        <polygon
          points="214.99 624.09 163.82 594.55 214.67 565.19 265.84 594.73 214.99 624.09"
          fill="#989898"
        />
        <polygon
          points="214.99 630.37 214.99 624.09 265.84 594.73 265.84 601.01 214.99 630.37"
          fill="#7e7e7e"
        />
        <polygon
          points="267.65 579.93 214.62 549.32 161.92 579.74 147.63 579.72 147.63 587.99 215.04 626.9 282.02 588.23 282.02 579.96 267.65 579.93"
          fill="#bababa"
        />
        <polygon
          points="215.04 618.63 147.63 579.72 214.62 541.04 282.02 579.96 215.04 618.63"
          fill="#d5d5d5"
        />
        <polygon
          points="215.04 626.9 215.04 618.63 282.02 579.96 282.02 588.23 215.04 626.9"
          fill="#9a9a9a"
        />
        <polygon
          points="254.93 573.54 214.67 550.3 174.66 573.4 163.82 573.38 163.82 579.66 214.99 609.2 265.84 579.84 265.84 573.56 254.93 573.54"
          fill="#939393"
        />
        <polygon
          points="214.99 602.92 163.82 573.38 214.67 544.02 265.84 573.56 214.99 602.92"
          fill="#989898"
        />
        <polygon
          points="214.99 609.2 214.99 602.92 265.84 573.56 265.84 579.84 214.99 609.2"
          fill="#7e7e7e"
        />
        <polygon
          points="267.65 558.76 214.62 528.14 161.92 558.57 147.63 558.54 147.63 566.82 215.04 605.73 282.02 567.06 282.02 558.79 267.65 558.76"
          fill="#bababa"
        />
        <polygon
          points="215.04 597.46 147.63 558.54 214.62 519.87 282.02 558.79 215.04 597.46"
          fill="#d5d5d5"
        />
        <polygon
          points="215.04 605.73 215.04 597.46 282.02 558.79 282.02 567.06 215.04 605.73"
          fill="#9a9a9a"
        />
        <polygon
          points="254.93 552.37 214.67 529.13 174.66 552.23 163.82 552.21 163.82 558.49 214.99 588.03 265.84 558.67 265.84 552.39 254.93 552.37"
          fill="#939393"
        />
        <polygon
          points="214.99 581.75 163.82 552.21 214.67 522.85 265.84 552.39 214.99 581.75"
          fill="#989898"
        />
        <polygon
          points="214.99 588.03 214.99 581.75 265.84 552.39 265.84 558.67 214.99 588.03"
          fill="#7e7e7e"
        />
        <polygon
          points="267.65 537.59 214.62 506.97 161.92 537.4 147.63 537.37 147.63 545.64 215.04 584.56 282.02 545.89 282.02 537.62 267.65 537.59"
          fill="#bababa"
        />
        <polygon
          points="215.04 576.29 147.63 537.37 214.62 498.7 282.02 537.62 215.04 576.29"
          fill="#d5d5d5"
        />
        <polygon
          points="215.04 584.56 215.04 576.29 282.02 537.62 282.02 545.89 215.04 584.56"
          fill="#9a9a9a"
        />
        <polygon
          points="254.93 531.2 214.67 507.95 174.66 531.05 163.82 531.03 163.82 537.31 214.99 566.86 265.84 537.5 265.84 531.22 254.93 531.2"
          fill="#939393"
        />
        <polygon
          points="214.99 560.58 163.82 531.03 214.67 501.68 265.84 531.22 214.99 560.58"
          fill="#989898"
        />
        <polygon
          points="214.99 566.86 214.99 560.58 265.84 531.22 265.84 537.5 214.99 566.86"
          fill="#7e7e7e"
        />
        <polygon
          points="267.65 516.42 214.62 485.8 161.92 516.23 147.63 516.2 147.63 524.47 215.04 563.39 282.02 524.72 282.02 516.45 267.65 516.42"
          fill="#bababa"
        />
        <polygon
          points="215.04 555.12 147.63 516.2 214.62 477.53 282.02 516.45 215.04 555.12"
          fill="#d5d5d5"
        />
        <polygon
          points="215.04 563.39 215.04 555.12 282.02 516.45 282.02 524.72 215.04 563.39"
          fill="#9a9a9a"
        />
        <polygon
          points="254.93 510.03 214.67 486.78 174.66 509.88 163.82 509.86 163.82 516.14 214.99 545.68 265.84 516.33 265.84 510.05 254.93 510.03"
          fill="#939393"
        />
        <polygon
          points="214.99 539.4 163.82 509.86 214.67 480.5 265.84 510.05 214.99 539.4"
          fill="#989898"
        />
        <polygon
          points="214.99 545.68 214.99 539.4 265.84 510.05 265.84 516.33 214.99 545.68"
          fill="#7e7e7e"
        />
        <polygon
          points="267.65 495.25 214.62 464.63 161.92 495.06 147.63 495.03 147.63 503.3 215.04 542.22 282.02 503.54 282.02 495.27 267.65 495.25"
          fill="#bababa"
        />
        <polygon
          points="215.04 533.94 147.63 495.03 214.62 456.36 282.02 495.27 215.04 533.94"
          fill="#d5d5d5"
        />
        <polygon
          points="215.04 542.22 215.04 533.94 282.02 495.27 282.02 503.54 215.04 542.22"
          fill="#9a9a9a"
        />
        <polygon
          points="254.93 488.85 214.67 465.61 174.66 488.71 163.82 488.69 163.82 494.97 214.99 524.51 265.84 495.15 265.84 488.87 254.93 488.85"
          fill="#bababa"
        />
        <polygon
          points="214.99 518.23 163.82 488.69 214.67 459.33 265.84 488.87 214.99 518.23"
          fill="#d5d5d5"
        />
        <polygon
          points="214.99 524.51 214.99 518.23 265.84 488.87 265.84 495.15 214.99 524.51"
          fill="#9a9a9a"
        />
        <polygon
          points="324 525.39 306.1 515.05 288.32 525.32 283.5 525.31 283.5 606.96 306.25 620.09 328.85 607.04 328.85 525.39 324 525.39"
          fill="#acacac"
        />
        <polygon
          points="306.25 538.45 283.5 525.31 306.1 512.26 328.85 525.39 306.25 538.45"
          fill="#ccc"
        />
        <polygon
          points="306.25 620.09 306.25 538.45 328.85 525.39 328.85 607.04 306.25 620.09"
          fill="#898989"
        />
        <polygon
          points="319.71 523.23 306.12 515.38 292.62 523.18 288.96 523.17 288.96 525.29 306.23 535.26 323.39 525.35 323.39 523.24 319.71 523.23"
          fill="#acacac"
        />
        <polygon
          points="306.23 533.14 288.96 523.17 306.12 513.26 323.39 523.24 306.23 533.14"
          fill="#c4c4c4"
        />
        <polygon
          points="306.23 535.26 306.23 533.14 323.39 523.24 323.39 525.35 306.23 535.26"
          fill="#898989"
        />
        <polygon
          points="331.15 658.68 298.18 639.65 265.42 658.56 256.54 658.55 256.54 663.69 298.44 687.88 340.08 663.84 340.08 658.7 331.15 658.68"
          fill="#acacac"
        />
        <polygon
          points="298.44 682.74 256.54 658.55 298.18 634.51 340.08 658.7 298.44 682.74"
          fill="#c4c4c4"
        />
        <polygon
          points="323.24 654.71 298.22 640.26 273.35 654.62 266.61 654.61 266.61 658.51 298.42 676.88 330.03 658.63 330.03 654.72 323.24 654.71"
          fill="#939393"
        />
        <polygon
          points="298.42 672.97 266.61 654.61 298.22 636.36 330.03 654.72 298.42 672.97"
          fill="#989898"
        />
        <polygon
          points="331.15 645.52 298.18 626.49 265.42 645.4 256.54 645.39 256.54 650.53 298.44 674.72 340.08 650.68 340.08 645.54 331.15 645.52"
          fill="#acacac"
        />
        <polygon
          points="298.44 669.58 256.54 645.39 298.18 621.35 340.08 645.54 298.44 669.58"
          fill="#c4c4c4"
        />
        <polygon
          points="323.24 641.55 298.22 627.1 273.35 641.46 266.61 641.45 266.61 645.35 298.42 663.71 330.03 645.46 330.03 641.56 323.24 641.55"
          fill="#939393"
        />
        <polygon
          points="298.42 659.81 266.61 641.45 298.22 623.2 330.03 641.56 298.42 659.81"
          fill="#989898"
        />
        <polygon
          points="298.42 663.71 298.42 659.81 330.03 641.56 330.03 645.46 298.42 663.71"
          fill="#7e7e7e"
        />
        <polygon
          points="331.15 632.36 298.18 613.33 265.42 632.24 256.54 632.23 256.54 637.37 298.44 661.56 340.08 637.52 340.08 632.38 331.15 632.36"
          fill="#acacac"
        />
        <polygon
          points="298.44 656.42 256.54 632.23 298.18 608.19 340.08 632.38 298.44 656.42"
          fill="#c4c4c4"
        />
        <polygon
          points="298.44 661.56 298.44 656.42 340.08 632.38 340.08 637.52 298.44 661.56"
          fill="#898989"
        />
        <polygon
          points="323.24 628.39 298.22 613.94 273.35 628.3 266.61 628.28 266.61 632.19 298.42 650.55 330.03 632.3 330.03 628.4 323.24 628.39"
          fill="#939393"
        />
        <polygon
          points="298.42 646.65 266.61 628.28 298.22 610.03 330.03 628.4 298.42 646.65"
          fill="#989898"
        />
        <polygon
          points="298.42 650.55 298.42 646.65 330.03 628.4 330.03 632.3 298.42 650.55"
          fill="#7e7e7e"
        />
        <polygon
          points="331.15 619.2 298.18 600.17 265.42 619.08 256.54 619.07 256.54 624.21 298.44 648.4 340.08 624.36 340.08 619.22 331.15 619.2"
          fill="#acacac"
        />
        <polygon
          points="298.44 643.26 256.54 619.07 298.18 595.02 340.08 619.22 298.44 643.26"
          fill="#c4c4c4"
        />
        <polygon
          points="298.44 648.4 298.44 643.26 340.08 619.22 340.08 624.36 298.44 648.4"
          fill="#898989"
        />
        <polygon
          points="323.24 615.23 298.22 600.78 273.35 615.14 266.61 615.12 266.61 619.03 298.42 637.39 330.03 619.14 330.03 615.24 323.24 615.23"
          fill="#939393"
        />
        <polygon
          points="298.42 633.49 266.61 615.12 298.22 596.87 330.03 615.24 298.42 633.49"
          fill="#989898"
        />
        <polygon
          points="298.42 637.39 298.42 633.49 330.03 615.24 330.03 619.14 298.42 637.39"
          fill="#7e7e7e"
        />
        <polygon
          points="331.15 606.04 298.18 587 265.42 605.92 256.54 605.9 256.54 611.04 298.44 635.24 340.08 611.2 340.08 606.05 331.15 606.04"
          fill="#acacac"
        />
        <polygon
          points="298.44 630.1 256.54 605.9 298.18 581.86 340.08 606.05 298.44 630.1"
          fill="#c4c4c4"
        />
        <polygon
          points="298.44 635.24 298.44 630.1 340.08 606.05 340.08 611.2 298.44 635.24"
          fill="#898989"
        />
        <polygon
          points="323.24 602.07 298.22 587.62 273.35 601.97 266.61 601.96 266.61 605.87 298.42 624.23 330.03 605.98 330.03 602.08 323.24 602.07"
          fill="#939393"
        />
        <polygon
          points="298.42 620.33 266.61 601.96 298.22 583.71 330.03 602.08 298.42 620.33"
          fill="#989898"
        />
        <polygon
          points="298.42 624.23 298.42 620.33 330.03 602.08 330.03 605.98 298.42 624.23"
          fill="#7e7e7e"
        />
        <polygon
          points="331.15 592.88 298.18 573.84 265.42 592.76 256.54 592.74 256.54 597.88 298.44 622.08 340.08 598.03 340.08 592.89 331.15 592.88"
          fill="#acacac"
        />
        <polygon
          points="298.44 616.93 256.54 592.74 298.18 568.7 340.08 592.89 298.44 616.93"
          fill="#c4c4c4"
        />
        <polygon
          points="298.44 622.08 298.44 616.93 340.08 592.89 340.08 598.03 298.44 622.08"
          fill="#898989"
        />
        <polygon
          points="323.24 588.9 298.22 574.45 273.35 588.81 266.61 588.8 266.61 592.71 298.42 611.07 330.03 592.82 330.03 588.92 323.24 588.9"
          fill="#939393"
        />
        <polygon
          points="298.42 607.17 266.61 588.8 298.22 570.55 330.03 588.92 298.42 607.17"
          fill="#989898"
        />
        <polygon
          points="298.42 611.07 298.42 607.17 330.03 588.92 330.03 592.82 298.42 611.07"
          fill="#7e7e7e"
        />
        <polygon
          points="331.15 579.72 298.18 560.68 265.42 579.6 256.54 579.58 256.54 584.72 298.44 608.91 340.08 584.87 340.08 579.73 331.15 579.72"
          fill="#acacac"
        />
        <polygon
          points="298.44 603.77 256.54 579.58 298.18 555.54 340.08 579.73 298.44 603.77"
          fill="#c4c4c4"
        />
        <polygon
          points="298.44 608.91 298.44 603.77 340.08 579.73 340.08 584.87 298.44 608.91"
          fill="#898989"
        />
        <polygon
          points="323.24 575.74 298.22 561.29 273.35 575.65 266.61 575.64 266.61 579.54 298.42 597.91 330.03 579.66 330.03 575.75 323.24 575.74"
          fill="#939393"
        />
        <polygon
          points="298.42 594 266.61 575.64 298.22 557.39 330.03 575.75 298.42 594"
          fill="#989898"
        />
        <polygon
          points="298.42 597.91 298.42 594 330.03 575.75 330.03 579.66 298.42 597.91"
          fill="#7e7e7e"
        />
        <polygon
          points="331.15 566.55 298.18 547.52 265.42 566.44 256.54 566.42 256.54 571.56 298.44 595.75 340.08 571.71 340.08 566.57 331.15 566.55"
          fill="#acacac"
        />
        <polygon
          points="298.44 590.61 256.54 566.42 298.18 542.38 340.08 566.57 298.44 590.61"
          fill="#c4c4c4"
        />
        <polygon
          points="298.44 595.75 298.44 590.61 340.08 566.57 340.08 571.71 298.44 595.75"
          fill="#898989"
        />
        <polygon
          points="323.24 562.58 298.22 548.13 273.35 562.49 266.61 562.48 266.61 566.38 298.42 584.75 330.03 566.5 330.03 562.59 323.24 562.58"
          fill="#acacac"
        />
        <polygon
          points="298.42 580.84 266.61 562.48 298.22 544.23 330.03 562.59 298.42 580.84"
          fill="#c4c4c4"
        />
        <polygon
          points="298.42 584.75 298.42 580.84 330.03 562.59 330.03 566.5 298.42 584.75"
          fill="#898989"
        />
        <polygon
          points="236.49 114.94 203.99 96.17 171.69 114.82 162.94 114.8 162.94 263.05 204.25 286.9 245.3 263.2 245.3 114.95 236.49 114.94"
          fill="#acacac"
        />
        <polygon
          points="204.25 138.65 162.94 114.8 203.99 91.1 245.3 114.95 204.25 138.65"
          fill="#ccc"
        />
        <polygon
          points="204.25 286.9 204.25 138.65 245.3 114.95 245.3 263.2 204.25 286.9"
          fill="#898989"
        />
        <polygon
          points="228.69 111.02 204.02 96.77 179.5 110.93 172.85 110.92 172.85 114.77 204.22 132.87 235.38 114.88 235.38 111.03 228.69 111.02"
          fill="#acacac"
        />
        <polygon
          points="204.22 129.02 172.85 110.92 204.02 92.92 235.38 111.03 204.22 129.02"
          fill="#c4c4c4"
        />
        <polygon
          points="204.22 132.87 204.22 129.02 235.38 111.03 235.38 114.88 204.22 132.87"
          fill="#898989"
        />
        <polygon
          points="433.44 178.65 412.12 166.33 390.92 178.57 385.17 178.56 385.17 275.85 412.28 291.5 439.22 275.95 439.22 178.66 433.44 178.65"
          fill="#acacac"
        />
        <polygon
          points="412.28 194.21 385.17 178.56 412.12 163.01 439.22 178.66 412.28 194.21"
          fill="#ccc"
        />
        <polygon
          points="412.28 291.5 412.28 194.21 439.22 178.66 439.22 275.95 412.28 291.5"
          fill="#898989"
        />
        <polygon
          points="428.33 176.08 412.14 166.73 396.04 176.02 391.68 176.01 391.68 178.53 412.26 190.42 432.72 178.61 432.72 176.08 428.33 176.08"
          fill="#acacac"
        />
        <polygon
          points="412.26 187.89 391.68 176.01 412.14 164.2 432.72 176.08 412.26 187.89"
          fill="#c4c4c4"
        />
        <polygon
          points="412.26 190.42 412.26 187.89 432.72 176.08 432.72 178.61 412.26 190.42"
          fill="#898989"
        />
        <polygon
          points="441.96 353.17 402.68 330.49 363.64 353.03 353.06 353.01 353.06 359.14 402.99 387.97 452.61 359.32 452.61 353.19 441.96 353.17"
          fill="#acacac"
        />
        <polygon
          points="402.99 381.84 353.06 353.01 402.68 324.37 452.61 353.19 402.99 381.84"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 387.97 402.99 381.84 452.61 353.19 452.61 359.32 402.99 387.97"
          fill="#898989"
        />
        <polygon
          points="432.54 348.44 402.71 331.22 373.08 348.33 365.05 348.32 365.05 352.97 402.95 374.85 440.62 353.11 440.62 348.45 432.54 348.44"
          fill="#939393"
        />
        <polygon
          points="402.95 370.2 365.05 348.32 402.71 326.57 440.62 348.45 402.95 370.2"
          fill="#989898"
        />
        <polygon
          points="402.95 374.85 402.95 370.2 440.62 348.45 440.62 353.11 402.95 374.85"
          fill="#7e7e7e"
        />
        <polygon
          points="441.96 337.49 402.68 314.81 363.64 337.35 353.06 337.33 353.06 343.46 402.99 372.28 452.61 343.63 452.61 337.51 441.96 337.49"
          fill="#acacac"
        />
        <polygon
          points="402.99 366.15 353.06 337.33 402.68 308.68 452.61 337.51 402.99 366.15"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 372.28 402.99 366.15 452.61 337.51 452.61 343.63 402.99 372.28"
          fill="#898989"
        />
        <polygon
          points="432.54 332.75 402.71 315.54 373.08 332.65 365.05 332.63 365.05 337.28 402.95 359.17 440.62 337.42 440.62 332.77 432.54 332.75"
          fill="#939393"
        />
        <polygon
          points="402.95 354.52 365.05 332.63 402.71 310.88 440.62 332.77 402.95 354.52"
          fill="#989898"
        />
        <polygon
          points="402.95 359.17 402.95 354.52 440.62 332.77 440.62 337.42 402.95 359.17"
          fill="#7e7e7e"
        />
        <polygon
          points="441.96 321.81 402.68 299.13 363.64 321.67 353.06 321.65 353.06 327.77 402.99 356.6 452.61 327.95 452.61 321.82 441.96 321.81"
          fill="#acacac"
        />
        <polygon
          points="402.99 350.47 353.06 321.65 402.68 293 452.61 321.82 402.99 350.47"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 356.6 402.99 350.47 452.61 321.82 452.61 327.95 402.99 356.6"
          fill="#898989"
        />
        <polygon
          points="432.54 317.07 402.71 299.85 373.08 316.96 365.05 316.95 365.05 321.6 402.95 343.49 440.62 321.74 440.62 317.09 432.54 317.07"
          fill="#939393"
        />
        <polygon
          points="402.95 338.83 365.05 316.95 402.71 295.2 440.62 317.09 402.95 338.83"
          fill="#989898"
        />
        <polygon
          points="402.95 343.49 402.95 338.83 440.62 317.09 440.62 321.74 402.95 343.49"
          fill="#7e7e7e"
        />
        <polygon
          points="441.96 306.12 402.68 283.44 363.64 305.98 353.06 305.96 353.06 312.09 402.99 340.92 452.61 312.27 452.61 306.14 441.96 306.12"
          fill="#acacac"
        />
        <polygon
          points="402.99 334.79 353.06 305.96 402.68 277.31 452.61 306.14 402.99 334.79"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 340.92 402.99 334.79 452.61 306.14 452.61 312.27 402.99 340.92"
          fill="#898989"
        />
        <polygon
          points="432.54 301.39 402.71 284.17 373.08 301.28 365.05 301.27 365.05 305.92 402.95 327.8 440.62 306.05 440.62 301.4 432.54 301.39"
          fill="#939393"
        />
        <polygon
          points="402.95 323.15 365.05 301.27 402.71 279.52 440.62 301.4 402.95 323.15"
          fill="#989898"
        />
        <polygon
          points="402.95 327.8 402.95 323.15 440.62 301.4 440.62 306.05 402.95 327.8"
          fill="#7e7e7e"
        />
        <polygon
          points="441.96 290.44 402.68 267.76 363.64 290.3 353.06 290.28 353.06 296.41 402.99 325.23 452.61 296.59 452.61 290.46 441.96 290.44"
          fill="#acacac"
        />
        <polygon
          points="402.99 319.11 353.06 290.28 402.68 261.63 452.61 290.46 402.99 319.11"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 325.23 402.99 319.11 452.61 290.46 452.61 296.59 402.99 325.23"
          fill="#898989"
        />
        <polygon
          points="432.54 285.7 402.71 268.49 373.08 285.6 365.05 285.58 365.05 290.23 402.95 312.12 440.62 290.37 440.62 285.72 432.54 285.7"
          fill="#939393"
        />
        <polygon
          points="402.95 307.47 365.05 285.58 402.71 263.83 440.62 285.72 402.95 307.47"
          fill="#989898"
        />
        <polygon
          points="402.95 312.12 402.95 307.47 440.62 285.72 440.62 290.37 402.95 312.12"
          fill="#7e7e7e"
        />
        <polygon
          points="441.96 274.76 402.68 252.07 363.64 274.61 353.06 274.6 353.06 280.72 402.99 309.55 452.61 280.9 452.61 274.77 441.96 274.76"
          fill="#acacac"
        />
        <polygon
          points="402.99 303.42 353.06 274.6 402.68 245.95 452.61 274.77 402.99 303.42"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 309.55 402.99 303.42 452.61 274.77 452.61 280.9 402.99 309.55"
          fill="#898989"
        />
        <polygon
          points="432.54 270.02 402.71 252.8 373.08 269.91 365.05 269.9 365.05 274.55 402.95 296.44 440.62 274.69 440.62 270.04 432.54 270.02"
          fill="#939393"
        />
        <polygon
          points="402.95 291.78 365.05 269.9 402.71 248.15 440.62 270.04 402.95 291.78"
          fill="#989898"
        />
        <polygon
          points="402.95 296.44 402.95 291.78 440.62 270.04 440.62 274.69 402.95 296.44"
          fill="#7e7e7e"
        />
        <polygon
          points="441.96 259.07 402.68 236.39 363.64 258.93 353.06 258.91 353.06 265.04 402.99 293.87 452.61 265.22 452.61 259.09 441.96 259.07"
          fill="#acacac"
        />
        <polygon
          points="402.99 287.74 353.06 258.91 402.68 230.26 452.61 259.09 402.99 287.74"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 293.87 402.99 287.74 452.61 259.09 452.61 265.22 402.99 293.87"
          fill="#898989"
        />
        <polygon
          points="432.54 254.34 402.71 237.12 373.08 254.23 365.05 254.22 365.05 258.87 402.95 280.75 440.62 259 440.62 254.35 432.54 254.34"
          fill="#939393"
        />
        <polygon
          points="402.95 276.1 365.05 254.22 402.71 232.47 440.62 254.35 402.95 276.1"
          fill="#989898"
        />
        <polygon
          points="402.95 280.75 402.95 276.1 440.62 254.35 440.62 259 402.95 280.75"
          fill="#7e7e7e"
        />
        <polygon
          points="441.96 243.39 402.68 220.71 363.64 243.25 353.06 243.23 353.06 249.36 402.99 278.18 452.61 249.53 452.61 243.41 441.96 243.39"
          fill="#acacac"
        />
        <polygon
          points="402.99 272.06 353.06 243.23 402.68 214.58 452.61 243.41 402.99 272.06"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 278.18 402.99 272.06 452.61 243.41 452.61 249.53 402.99 278.18"
          fill="#898989"
        />
        <polygon
          points="432.54 238.65 402.71 221.44 373.08 238.55 365.05 238.53 365.05 243.18 402.95 265.07 440.62 243.32 440.62 238.67 432.54 238.65"
          fill="#939393"
        />
        <polygon
          points="402.95 260.42 365.05 238.53 402.71 216.78 440.62 238.67 402.95 260.42"
          fill="#989898"
        />
        <polygon
          points="402.95 265.07 402.95 260.42 440.62 238.67 440.62 243.32 402.95 265.07"
          fill="#7e7e7e"
        />
        <polygon
          points="441.96 227.71 402.68 205.03 363.64 227.56 353.06 227.54 353.06 233.67 402.99 262.5 452.61 233.85 452.61 227.72 441.96 227.71"
          fill="#acacac"
        />
        <polygon
          points="402.99 256.37 353.06 227.54 402.68 198.9 452.61 227.72 402.99 256.37"
          fill="#c4c4c4"
        />
        <polygon
          points="402.99 262.5 402.99 256.37 452.61 227.72 452.61 233.85 402.99 262.5"
          fill="#898989"
        />
        <polygon
          points="432.54 222.97 402.71 205.75 373.08 222.86 365.05 222.85 365.05 227.5 402.95 249.38 440.62 227.64 440.62 222.99 432.54 222.97"
          fill="#acacac"
        />
        <polygon
          points="402.95 244.73 365.05 222.85 402.71 201.1 440.62 222.99 402.95 244.73"
          fill="#c4c4c4"
        />
        <polygon
          points="402.95 249.38 402.95 244.73 440.62 222.99 440.62 227.64 402.95 249.38"
          fill="#898989"
        />
        <polygon
          points="361.41 206.59 328.91 187.82 296.61 206.47 287.86 206.46 287.86 354.71 329.17 378.56 370.22 354.86 370.22 206.61 361.41 206.59"
          fill="#acacac"
        />
        <polygon
          points="329.17 230.31 287.86 206.46 328.91 182.76 370.22 206.61 329.17 230.31"
          fill="#ccc"
        />
        <polygon
          points="329.17 378.56 329.17 230.31 370.22 206.61 370.22 354.86 329.17 378.56"
          fill="#898989"
        />
        <polygon
          points="353.62 202.67 328.94 188.43 304.42 202.58 297.78 202.57 297.78 206.42 329.14 224.53 360.3 206.53 360.3 202.69 353.62 202.67"
          fill="#acacac"
        />
        <polygon
          points="329.14 220.68 297.78 202.57 328.94 184.58 360.3 202.69 329.14 220.68"
          fill="#c4c4c4"
        />
        <polygon
          points="329.14 224.53 329.14 220.68 360.3 202.69 360.3 206.53 329.14 224.53"
          fill="#898989"
        />
        <polygon
          points="288.81 383.82 225.61 347.33 162.81 383.59 145.79 383.56 145.79 393.42 226.11 439.79 305.93 393.71 305.93 383.85 288.81 383.82"
          fill="#bababa"
        />
        <polygon
          points="226.11 429.94 145.79 383.56 225.61 337.48 305.93 383.85 226.11 429.94"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 439.79 226.11 429.94 305.93 383.85 305.93 393.71 226.11 439.79"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 376.2 225.68 348.5 178 376.03 165.08 376.01 165.08 383.49 226.06 418.7 286.65 383.71 286.65 376.23 273.65 376.2"
          fill="#939393"
        />
        <polygon
          points="226.06 411.21 165.08 376.01 225.68 341.02 286.65 376.23 226.06 411.21"
          fill="#989898"
        />
        <polygon
          points="226.06 418.7 226.06 411.21 286.65 376.23 286.65 383.71 226.06 418.7"
          fill="#7e7e7e"
        />
        <polygon
          points="288.81 358.59 225.61 322.11 162.81 358.36 145.79 358.33 145.79 368.19 226.11 414.56 305.93 368.48 305.93 358.62 288.81 358.59"
          fill="#bababa"
        />
        <polygon
          points="226.11 404.71 145.79 358.33 225.61 312.25 305.93 358.62 226.11 404.71"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 414.56 226.11 404.71 305.93 358.62 305.93 368.48 226.11 414.56"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 350.98 225.68 323.27 178 350.8 165.08 350.78 165.08 358.26 226.06 393.47 286.65 358.48 286.65 351 273.65 350.98"
          fill="#939393"
        />
        <polygon
          points="226.06 385.98 165.08 350.78 225.68 315.79 286.65 351 226.06 385.98"
          fill="#989898"
        />
        <polygon
          points="226.06 393.47 226.06 385.98 286.65 351 286.65 358.48 226.06 393.47"
          fill="#7e7e7e"
        />
        <polygon
          points="288.81 333.36 225.61 296.88 162.81 333.13 145.79 333.1 145.79 342.96 226.11 389.33 305.93 343.25 305.93 333.39 288.81 333.36"
          fill="#bababa"
        />
        <polygon
          points="226.11 379.48 145.79 333.1 225.61 287.02 305.93 333.39 226.11 379.48"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 389.33 226.11 379.48 305.93 333.39 305.93 343.25 226.11 389.33"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 325.75 225.68 298.05 178 325.57 165.08 325.55 165.08 333.03 226.06 368.24 286.65 333.25 286.65 325.77 273.65 325.75"
          fill="#939393"
        />
        <polygon
          points="226.06 360.75 165.08 325.55 225.68 290.56 286.65 325.77 226.06 360.75"
          fill="#989898"
        />
        <polygon
          points="226.06 368.24 226.06 360.75 286.65 325.77 286.65 333.25 226.06 368.24"
          fill="#7e7e7e"
        />
        <polygon
          points="288.81 308.13 225.61 271.65 162.81 307.9 145.79 307.87 145.79 317.73 226.11 364.1 305.93 318.02 305.93 308.16 288.81 308.13"
          fill="#bababa"
        />
        <polygon
          points="226.11 354.25 145.79 307.87 225.61 261.79 305.93 308.16 226.11 354.25"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 364.1 226.11 354.25 305.93 308.16 305.93 318.02 226.11 364.1"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 300.52 225.68 272.82 178 300.34 165.08 300.32 165.08 307.8 226.06 343.01 286.65 308.02 286.65 300.54 273.65 300.52"
          fill="#939393"
        />
        <polygon
          points="226.06 335.52 165.08 300.32 225.68 265.33 286.65 300.54 226.06 335.52"
          fill="#989898"
        />
        <polygon
          points="226.06 343.01 226.06 335.52 286.65 300.54 286.65 308.02 226.06 343.01"
          fill="#7e7e7e"
        />
        <polygon
          points="288.81 282.9 225.61 246.42 162.81 282.68 145.79 282.64 145.79 292.5 226.11 338.88 305.93 292.79 305.93 282.94 288.81 282.9"
          fill="#bababa"
        />
        <polygon
          points="226.11 329.02 145.79 282.64 225.61 236.56 305.93 282.94 226.11 329.02"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 338.88 226.11 329.02 305.93 282.94 305.93 292.79 226.11 338.88"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 275.29 225.68 247.59 178 275.11 165.08 275.09 165.08 282.57 226.06 317.78 286.65 282.79 286.65 275.31 273.65 275.29"
          fill="#939393"
        />
        <polygon
          points="226.06 310.3 165.08 275.09 225.68 240.1 286.65 275.31 226.06 310.3"
          fill="#989898"
        />
        <polygon
          points="226.06 317.78 226.06 310.3 286.65 275.31 286.65 282.79 226.06 317.78"
          fill="#7e7e7e"
        />
        <polygon
          points="288.81 257.67 225.61 221.19 162.81 257.45 145.79 257.42 145.79 267.27 226.11 313.64 305.93 267.56 305.93 257.7 288.81 257.67"
          fill="#bababa"
        />
        <polygon
          points="226.11 303.79 145.79 257.42 225.61 211.33 305.93 257.7 226.11 303.79"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 313.64 226.11 303.79 305.93 257.7 305.93 267.56 226.11 313.64"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 250.06 225.68 222.36 178 249.88 165.08 249.86 165.08 257.34 226.06 292.55 286.65 257.56 286.65 250.08 273.65 250.06"
          fill="#939393"
        />
        <polygon
          points="226.06 285.07 165.08 249.86 225.68 214.88 286.65 250.08 226.06 285.07"
          fill="#989898"
        />
        <polygon
          points="226.06 292.55 226.06 285.07 286.65 250.08 286.65 257.56 226.06 292.55"
          fill="#7e7e7e"
        />
        <polygon
          points="288.81 232.44 225.61 195.96 162.81 232.22 145.79 232.19 145.79 242.04 226.11 288.42 305.93 242.33 305.93 232.47 288.81 232.44"
          fill="#bababa"
        />
        <polygon
          points="226.11 278.56 145.79 232.19 225.61 186.1 305.93 232.47 226.11 278.56"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 288.42 226.11 278.56 305.93 232.47 305.93 242.33 226.11 288.42"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 224.83 225.68 197.13 178 224.66 165.08 224.63 165.08 232.11 226.06 267.32 286.65 232.33 286.65 224.85 273.65 224.83"
          fill="#939393"
        />
        <polygon
          points="226.06 259.84 165.08 224.63 225.68 189.65 286.65 224.85 226.06 259.84"
          fill="#989898"
        />
        <polygon
          points="226.06 267.32 226.06 259.84 286.65 224.85 286.65 232.33 226.06 267.32"
          fill="#7e7e7e"
        />
        <polygon
          points="288.81 207.22 225.61 170.73 162.81 206.99 145.79 206.96 145.79 216.81 226.11 263.19 305.93 217.1 305.93 207.25 288.81 207.22"
          fill="#bababa"
        />
        <polygon
          points="226.11 253.33 145.79 206.96 225.61 160.87 305.93 207.25 226.11 253.33"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 263.19 226.11 253.33 305.93 207.25 305.93 217.1 226.11 263.19"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 199.6 225.68 171.9 178 199.43 165.08 199.4 165.08 206.88 226.06 242.09 286.65 207.1 286.65 199.62 273.65 199.6"
          fill="#939393"
        />
        <polygon
          points="226.06 234.61 165.08 199.4 225.68 164.42 286.65 199.62 226.06 234.61"
          fill="#989898"
        />
        <polygon
          points="226.06 242.09 226.06 234.61 286.65 199.62 286.65 207.1 226.06 242.09"
          fill="#7e7e7e"
        />
        <polygon
          points="288.81 181.99 225.61 145.5 162.81 181.76 145.79 181.73 145.79 191.58 226.11 237.96 305.93 191.87 305.93 182.02 288.81 181.99"
          fill="#bababa"
        />
        <polygon
          points="226.11 228.1 145.79 181.73 225.61 135.64 305.93 182.02 226.11 228.1"
          fill="#d5d5d5"
        />
        <polygon
          points="226.11 237.96 226.11 228.1 305.93 182.02 305.93 191.87 226.11 237.96"
          fill="#9a9a9a"
        />
        <polygon
          points="273.65 174.37 225.68 146.67 178 174.2 165.08 174.17 165.08 181.66 226.06 216.86 286.65 181.88 286.65 174.39 273.65 174.37"
          fill="#bababa"
        />
        <polygon
          points="226.06 209.38 165.08 174.17 225.68 139.19 286.65 174.39 226.06 209.38"
          fill="#d5d5d5"
        />
        <polygon
          points="226.06 216.86 226.06 209.38 286.65 174.39 286.65 181.88 226.06 216.86"
          fill="#9a9a9a"
        />
        <polygon
          points="355.58 279.09 320.73 258.97 286.1 278.96 276.71 278.95 276.71 437.91 321 463.49 365.02 438.07 365.02 279.11 355.58 279.09"
          fill="#b3b3b3"
        />
        <polygon
          points="321 304.52 276.71 278.95 320.73 253.53 365.02 279.11 321 304.52"
          fill="#ccc"
        />
        <polygon
          points="321 463.49 321 304.52 365.02 279.11 365.02 438.07 321 463.49"
          fill="#909090"
        />
        <polygon
          points="347.22 274.89 320.76 259.61 294.47 274.79 287.35 274.78 287.35 278.91 320.97 298.32 354.39 279.03 354.39 274.9 347.22 274.89"
          fill="#b3b3b3"
        />
        <polygon
          points="320.97 294.2 287.35 274.78 320.76 255.49 354.39 274.9 320.97 294.2"
          fill="#c4c4c4"
        />
        <polygon
          points="320.97 298.32 320.97 294.2 354.39 274.9 354.39 279.03 320.97 298.32"
          fill="#909090"
        />
        <polygon
          points="402.29 341.42 378.95 327.95 355.76 341.34 349.47 341.33 349.47 447.78 379.14 464.9 408.61 447.88 408.61 341.43 402.29 341.42"
          fill="#b3b3b3"
        />
        <polygon
          points="379.14 358.45 349.47 341.33 378.95 324.31 408.61 341.43 379.14 358.45"
          fill="#ccc"
        />
        <polygon
          points="379.14 464.9 379.14 358.45 408.61 341.43 408.61 447.88 379.14 464.9"
          fill="#909090"
        />
        <polygon
          points="396.69 338.61 378.98 328.38 361.37 338.55 356.6 338.54 356.6 341.3 379.12 354.3 401.49 341.38 401.49 338.62 396.69 338.61"
          fill="#b3b3b3"
        />
        <polygon
          points="379.12 351.54 356.6 338.54 378.98 325.62 401.49 338.62 379.12 351.54"
          fill="#c4c4c4"
        />
        <polygon
          points="379.12 354.3 379.12 351.54 401.49 338.62 401.49 341.38 379.12 354.3"
          fill="#909090"
        />
        <polygon
          points="298.32 365 279.17 353.95 260.14 364.94 254.99 364.93 254.99 452.27 279.32 466.32 303.51 452.36 303.51 365.01 298.32 365"
          fill="#b3b3b3"
        />
        <polygon
          points="279.32 378.98 254.99 364.93 279.17 350.96 303.51 365.01 279.32 378.98"
          fill="#ccc"
        />
        <polygon
          points="279.32 466.32 279.32 378.98 303.51 365.01 303.51 452.36 279.32 466.32"
          fill="#909090"
        />
        <polygon
          points="293.73 362.7 279.19 354.3 264.75 362.64 260.83 362.64 260.83 364.91 279.31 375.57 297.67 364.97 297.67 362.7 293.73 362.7"
          fill="#b3b3b3"
        />
        <polygon
          points="279.31 373.31 260.83 362.64 279.19 352.04 297.67 362.7 279.31 373.31"
          fill="#c4c4c4"
        />
        <polygon
          points="279.31 375.57 279.31 373.31 297.67 362.7 297.67 364.97 279.31 375.57"
          fill="#909090"
        />
        <polygon
          points="317.28 365.11 280.31 343.76 280.31 345.11 317.28 366.45 317.28 365.11"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 361.41 280.31 340.06 280.31 341.41 317.28 362.76 317.28 361.41"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 357.71 280.31 336.36 280.31 337.71 317.28 359.06 317.28 357.71"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 354.01 280.31 332.66 280.31 334.01 317.28 355.36 317.28 354.01"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 350.31 280.31 328.97 280.31 330.31 317.28 351.66 317.28 350.31"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 346.61 280.31 325.27 280.31 326.62 317.28 347.96 317.28 346.61"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 342.92 280.31 321.57 280.31 322.92 317.28 344.26 317.28 342.92"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 339.22 280.31 317.87 280.31 319.22 317.28 340.57 317.28 339.22"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 335.52 280.31 314.17 280.31 315.52 317.28 336.87 317.28 335.52"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 331.82 280.31 310.47 280.31 311.82 317.28 333.17 317.28 331.82"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 328.12 280.31 306.78 280.31 308.12 317.28 329.47 317.28 328.12"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 324.42 280.31 303.08 280.31 304.43 317.28 325.77 317.28 324.42"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 320.73 280.31 299.38 280.31 300.73 317.28 322.07 317.28 320.73"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 317.03 280.31 295.68 280.31 297.03 317.28 318.38 317.28 317.03"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 313.33 280.31 291.98 280.31 293.33 317.28 314.68 317.28 313.33"
          fill="#d5d5d5"
        />
        <polygon
          points="317.28 309.63 280.31 288.28 280.31 289.63 317.28 310.98 317.28 309.63"
          fill="#d5d5d5"
        />
        <polygon
          id="window-1"
          points="389.29 394.67 385.89 396.63 385.89 386.29 389.29 384.32 389.29 394.67"
          fill="#d5d5d5"
        />
        <polygon
          points="394.11 391.89 390.71 393.85 390.71 383.51 394.11 381.54 394.11 391.89"
          fill="#d5d5d5"
        />
        <polygon
          points="398.93 389.11 395.53 391.07 395.53 380.73 398.93 378.76 398.93 389.11"
          fill="#d5d5d5"
        />
        <polygon
          points="403.74 386.33 400.34 388.29 400.34 377.95 403.74 375.98 403.74 386.33"
          fill="#d5d5d5"
        />
        <polygon
          points="389.29 406.36 385.89 408.32 385.89 397.98 389.29 396.01 389.29 406.36"
          fill="#d5d5d5"
        />
        <polygon
          points="394.11 403.58 390.71 405.54 390.71 395.2 394.11 393.23 394.11 403.58"
          fill="#d5d5d5"
        />
        <polygon
          points="398.93 400.8 395.53 402.76 395.53 392.42 398.93 390.45 398.93 400.8"
          fill="#d5d5d5"
        />
        <polygon
          points="403.74 398.02 400.34 399.98 400.34 389.64 403.74 387.67 403.74 398.02"
          fill="#d5d5d5"
        />
        <polygon
          points="389.29 371.29 385.89 373.25 385.89 362.91 389.29 360.94 389.29 371.29"
          fill="#d5d5d5"
        />
        <polygon
          points="394.11 368.51 390.71 370.47 390.71 360.13 394.11 358.16 394.11 368.51"
          fill="#d5d5d5"
        />
        <polygon
          id="window-2"
          points="398.93 365.73 395.53 367.69 395.53 357.35 398.93 355.38 398.93 365.73"
          fill="#d5d5d5"
        />
        <polygon
          points="403.74 362.95 400.34 364.91 400.34 354.57 403.74 352.6 403.74 362.95"
          fill="#d5d5d5"
        />
        <polygon
          points="389.29 382.98 385.89 384.94 385.89 374.6 389.29 372.63 389.29 382.98"
          fill="#d5d5d5"
        />
        <polygon
          points="394.11 380.2 390.71 382.16 390.71 371.82 394.11 369.86 394.11 380.2"
          fill="#d5d5d5"
        />
        <polygon
          points="398.93 377.42 395.53 379.38 395.53 369.04 398.93 367.07 398.93 377.42"
          fill="#d5d5d5"
        />
        <polygon
          points="403.74 374.64 400.34 376.6 400.34 366.26 403.74 364.29 403.74 374.64"
          fill="#d5d5d5"
        />
        <polygon
          id="window-5"
          points="389.29 441.43 385.89 443.39 385.89 433.05 389.29 431.08 389.29 441.43"
          fill="#d5d5d5"
        />
        <polygon
          points="394.11 438.65 390.71 440.61 390.71 430.27 394.11 428.3 394.11 438.65"
          fill="#d5d5d5"
        />
        <polygon
          points="398.93 435.87 395.53 437.83 395.53 427.49 398.93 425.52 398.93 435.87"
          fill="#d5d5d5"
        />
        <polygon
          points="403.74 433.09 400.34 435.05 400.34 424.71 403.74 422.74 403.74 433.09"
          fill="#d5d5d5"
        />
        <polygon
          points="389.29 453.12 385.89 455.08 385.89 444.74 389.29 442.77 389.29 453.12"
          fill="#d5d5d5"
        />
        <polygon
          points="394.11 450.34 390.71 452.3 390.71 441.96 394.11 439.99 394.11 450.34"
          fill="#d5d5d5"
        />
        <polygon
          points="398.93 447.56 395.53 449.52 395.53 439.18 398.93 437.21 398.93 447.56"
          fill="#d5d5d5"
        />
        <polygon
          points="403.74 444.78 400.34 446.74 400.34 436.39 403.74 434.43 403.74 444.78"
          fill="#d5d5d5"
        />
        <polygon
          points="389.29 418.05 385.89 420.01 385.89 409.67 389.29 407.7 389.29 418.05"
          fill="#d5d5d5"
        />
        <polygon
          points="394.11 415.27 390.71 417.23 390.71 406.89 394.11 404.92 394.11 415.27"
          fill="#d5d5d5"
        />
        <polygon
          id="window-3"
          points="398.93 412.49 395.53 414.45 395.53 404.11 398.93 402.14 398.93 412.49"
          fill="#d5d5d5"
        />
        <polygon
          points="403.74 409.71 400.34 411.67 400.34 401.33 403.74 399.36 403.74 409.71"
          fill="#d5d5d5"
        />
        <polygon
          points="389.29 429.74 385.89 431.7 385.89 421.36 389.29 419.39 389.29 429.74"
          fill="#d5d5d5"
        />
        <polygon
          points="394.11 426.96 390.71 428.92 390.71 418.58 394.11 416.61 394.11 426.96"
          fill="#d5d5d5"
        />
        <polygon
          points="398.93 424.18 395.53 426.14 395.53 415.8 398.93 413.83 398.93 424.18"
          fill="#d5d5d5"
        />
        <polygon
          id="window-4"
          points="403.74 421.4 400.34 423.36 400.34 413.01 403.74 411.05 403.74 421.4"
          fill="#d5d5d5"
        />
        <polygon
          points="262.24 423.47 259.43 421.85 259.43 430.41 262.24 432.03 262.24 423.47"
          fill="#d5d5d5"
        />
        <polygon
          id="window-14"
          points="266.22 425.77 263.41 424.15 263.41 432.7 266.22 434.33 266.22 425.77"
          fill="#d5d5d5"
        />
        <polygon
          points="270.2 428.07 267.39 426.45 267.39 435 270.2 436.63 270.2 428.07"
          fill="#d5d5d5"
        />
        <polygon
          points="274.19 430.37 271.37 428.75 271.37 437.31 274.19 438.93 274.19 430.37"
          fill="#d5d5d5"
        />
        <polygon
          points="262.24 413.81 259.43 412.18 259.43 420.74 262.24 422.36 262.24 413.81"
          fill="#d5d5d5"
        />
        <polygon
          points="266.22 416.11 263.41 414.48 263.41 423.04 266.22 424.66 266.22 416.11"
          fill="#d5d5d5"
        />
        <polygon
          points="270.2 418.4 267.39 416.78 267.39 425.34 270.2 426.96 270.2 418.4"
          fill="#d5d5d5"
        />
        <polygon
          id="window-13"
          points="274.19 420.7 271.37 419.08 271.37 427.64 274.19 429.26 274.19 420.7"
          fill="#d5d5d5"
        />
        <polygon
          points="262.24 442.81 259.43 441.19 259.43 449.74 262.24 451.37 262.24 442.81"
          fill="#d5d5d5"
        />
        <polygon
          points="266.22 445.11 263.41 443.49 263.41 452.04 266.22 453.67 266.22 445.11"
          fill="#d5d5d5"
        />
        <polygon
          id="window-15"
          points="270.2 447.41 267.39 445.79 267.39 454.34 270.2 455.97 270.2 447.41"
          fill="#d5d5d5"
        />
        <polygon
          points="274.19 449.71 271.37 448.09 271.37 456.64 274.19 458.26 274.19 449.71"
          fill="#d5d5d5"
        />
        <polygon
          points="262.24 433.14 259.43 431.52 259.43 440.07 262.24 441.7 262.24 433.14"
          fill="#d5d5d5"
        />
        <polygon
          points="266.22 435.44 263.41 433.82 263.41 442.38 266.22 444 266.22 435.44"
          fill="#d5d5d5"
        />
        <polygon
          points="270.2 437.74 267.39 436.12 267.39 444.67 270.2 446.3 270.2 437.74"
          fill="#d5d5d5"
        />
        <polygon
          points="274.19 440.04 271.37 438.42 271.37 446.97 274.19 448.6 274.19 440.04"
          fill="#d5d5d5"
        />
        <polygon
          id="window-11"
          points="262.24 384.8 259.43 383.18 259.43 391.73 262.24 393.36 262.24 384.8"
          fill="#d5d5d5"
        />
        <polygon
          points="266.22 387.1 263.41 385.48 263.41 394.03 266.22 395.65 266.22 387.1"
          fill="#d5d5d5"
        />
        <polygon
          points="270.2 389.4 267.39 387.78 267.39 396.33 270.2 397.96 270.2 389.4"
          fill="#d5d5d5"
        />
        <polygon
          points="274.19 391.7 271.37 390.07 271.37 398.63 274.19 400.25 274.19 391.7"
          fill="#d5d5d5"
        />
        <polygon
          points="262.24 375.13 259.43 373.51 259.43 382.06 262.24 383.69 262.24 375.13"
          fill="#d5d5d5"
        />
        <polygon
          points="266.22 377.43 263.41 375.81 263.41 384.36 266.22 385.99 266.22 377.43"
          fill="#d5d5d5"
        />
        <polygon
          points="270.2 379.73 267.39 378.11 267.39 386.66 270.2 388.29 270.2 379.73"
          fill="#d5d5d5"
        />
        <polygon
          points="274.19 382.03 271.37 380.41 271.37 388.96 274.19 390.59 274.19 382.03"
          fill="#d5d5d5"
        />
        <polygon
          points="262.24 404.14 259.43 402.51 259.43 411.07 262.24 412.69 262.24 404.14"
          fill="#d5d5d5"
        />
        <polygon
          points="266.22 406.44 263.41 404.81 263.41 413.37 266.22 414.99 266.22 406.44"
          fill="#d5d5d5"
        />
        <polygon
          points="270.2 408.74 267.39 407.11 267.39 415.67 270.2 417.29 270.2 408.74"
          fill="#d5d5d5"
        />
        <polygon
          points="274.19 411.04 271.37 409.41 271.37 417.97 274.19 419.59 274.19 411.04"
          fill="#d5d5d5"
        />
        <polygon
          points="262.24 394.47 259.43 392.85 259.43 401.4 262.24 403.02 262.24 394.47"
          fill="#d5d5d5"
        />
        <polygon
          id="window-12"
          points="266.22 396.77 263.41 395.14 263.41 403.7 266.22 405.32 266.22 396.77"
          fill="#d5d5d5"
        />
        <polygon
          points="270.2 399.07 267.39 397.44 267.39 406 270.2 407.62 270.2 399.07"
          fill="#d5d5d5"
        />
        <polygon
          points="274.19 401.37 271.37 399.75 271.37 408.3 274.19 409.92 274.19 401.37"
          fill="#d5d5d5"
        />
        <polygon
          id="window-9"
          points="357.97 412.66 354.57 410.7 354.57 421.05 357.97 423.01 357.97 412.66"
          fill="#d5d5d5"
        />
        <polygon
          points="362.79 415.44 359.39 413.48 359.39 423.82 362.79 425.79 362.79 415.44"
          fill="#d5d5d5"
        />
        <polygon
          points="367.6 418.22 364.2 416.26 364.2 426.61 367.6 428.57 367.6 418.22"
          fill="#d5d5d5"
        />
        <polygon
          points="372.42 421 369.02 419.04 369.02 429.38 372.42 431.35 372.42 421"
          fill="#d5d5d5"
        />
        <polygon
          points="357.97 400.97 354.57 399.01 354.57 409.35 357.97 411.32 357.97 400.97"
          fill="#d5d5d5"
        />
        <polygon
          points="362.79 403.75 359.39 401.79 359.39 412.13 362.79 414.1 362.79 403.75"
          fill="#d5d5d5"
        />
        <polygon
          points="367.6 406.53 364.2 404.57 364.2 414.92 367.6 416.88 367.6 406.53"
          fill="#d5d5d5"
        />
        <polygon
          id="window-8"
          points="372.42 409.31 369.02 407.35 369.02 417.69 372.42 419.66 372.42 409.31"
          fill="#d5d5d5"
        />
        <polygon
          points="357.97 436.04 354.57 434.08 354.57 444.43 357.97 446.39 357.97 436.04"
          fill="#d5d5d5"
        />
        <polygon
          points="362.79 438.82 359.39 436.86 359.39 447.2 362.79 449.17 362.79 438.82"
          fill="#d5d5d5"
        />
        <polygon
          points="367.6 441.6 364.2 439.64 364.2 449.99 367.6 451.95 367.6 441.6"
          fill="#d5d5d5"
        />
        <polygon
          points="372.42 444.38 369.02 442.42 369.02 452.77 372.42 454.73 372.42 444.38"
          fill="#d5d5d5"
        />
        <polygon
          points="357.97 424.35 354.57 422.39 354.57 432.74 357.97 434.7 357.97 424.35"
          fill="#d5d5d5"
        />
        <polygon
          id="window-10"
          points="362.79 427.13 359.39 425.17 359.39 435.51 362.79 437.48 362.79 427.13"
          fill="#d5d5d5"
        />
        <polygon
          points="367.6 429.91 364.2 427.95 364.21 438.3 367.6 440.26 367.6 429.91"
          fill="#d5d5d5"
        />
        <polygon
          points="372.42 432.69 369.02 430.73 369.02 441.07 372.42 443.04 372.42 432.69"
          fill="#d5d5d5"
        />
        <polygon
          points="357.97 365.9 354.57 363.94 354.57 374.29 357.97 376.25 357.97 365.9"
          fill="#d5d5d5"
        />
        <polygon
          points="362.79 368.68 359.39 366.72 359.39 377.07 362.79 379.03 362.79 368.68"
          fill="#d5d5d5"
        />
        <polygon
          id="window-6"
          points="367.6 371.46 364.21 369.5 364.2 379.85 367.6 381.81 367.6 371.46"
          fill="#d5d5d5"
        />
        <polygon
          points="372.42 374.24 369.02 372.28 369.02 382.63 372.42 384.59 372.42 374.24"
          fill="#d5d5d5"
        />
        <polygon
          points="357.97 354.21 354.57 352.25 354.57 362.6 357.97 364.56 357.97 354.21"
          fill="#d5d5d5"
        />
        <polygon
          points="362.79 356.99 359.39 355.03 359.39 365.38 362.79 367.34 362.79 356.99"
          fill="#d5d5d5"
        />
        <polygon
          points="367.6 359.77 364.2 357.81 364.2 368.16 367.6 370.12 367.6 359.77"
          fill="#d5d5d5"
        />
        <polygon
          points="372.42 362.55 369.02 360.59 369.02 370.94 372.42 372.9 372.42 362.55"
          fill="#d5d5d5"
        />
        <polygon
          points="357.97 389.28 354.57 387.32 354.57 397.67 357.97 399.63 357.97 389.28"
          fill="#d5d5d5"
        />
        <polygon
          points="362.79 392.06 359.39 390.1 359.39 400.44 362.79 402.41 362.79 392.06"
          fill="#d5d5d5"
        />
        <polygon
          points="367.6 394.84 364.21 392.88 364.2 403.23 367.6 405.19 367.6 394.84"
          fill="#d5d5d5"
        />
        <polygon
          points="372.42 397.62 369.02 395.66 369.02 406.01 372.42 407.97 372.42 397.62"
          fill="#d5d5d5"
        />
        <polygon
          points="357.97 377.59 354.57 375.63 354.57 385.98 357.97 387.94 357.97 377.59"
          fill="#d5d5d5"
        />
        <polygon
          id="window-7"
          points="362.79 380.37 359.39 378.41 359.39 388.75 362.79 390.72 362.79 380.37"
          fill="#d5d5d5"
        />
        <polygon
          points="367.6 383.15 364.2 381.19 364.2 391.54 367.6 393.5 367.6 383.15"
          fill="#d5d5d5"
        />
        <polygon
          points="372.42 385.93 369.02 383.97 369.02 394.32 372.42 396.28 372.42 385.93"
          fill="#d5d5d5"
        />
        <polygon
          points="598.98 409.6 597.56 408.78 596.14 409.6 596.14 409.6 596.14 409.6 596.14 416.21 596.14 416.21 596.14 421.58 596.14 421.58 596.14 421.58 596.14 421.58 596.14 421.58 597.57 422.41 598.99 421.58 598.98 421.58 598.99 421.58 598.99 416.21 598.99 414.97 598.99 409.6 598.98 409.6"
          fill="#787878"
        />
        <polygon
          points="596.14 409.6 596.14 416.21 596.14 416.21 596.14 421.58 596.14 421.58 596.14 421.58 597.56 422.4 597.56 409.6 596.14 409.6"
          fill="#787878"
        />
        <polygon
          points="597.57 410.42 596.14 409.6 597.56 408.78 598.99 409.6 597.57 410.42"
          fill="#787878"
        />
        <path
          d="M604.23,410a6.67,6.67,0,0,1-13.34,0c0-3.69,3-17.15,6.67-17.15S604.23,406.33,604.23,410Z"
          fill="#acacac"
        />
        <polygon
          points="580.09 427.59 578.66 426.77 577.24 427.59 577.24 427.59 577.24 427.59 577.24 434.2 577.24 434.2 577.24 439.57 577.24 439.57 577.24 439.57 577.24 439.57 577.24 439.57 578.67 440.4 580.09 439.58 580.09 439.57 580.09 439.57 580.09 434.2 580.09 432.96 580.09 427.59 580.09 427.59"
          fill="#787878"
        />
        <polygon
          points="577.24 427.59 577.24 434.2 577.24 434.2 577.24 439.57 577.24 439.57 577.24 439.57 578.66 440.4 578.66 427.59 577.24 427.59"
          fill="#787878"
        />
        <polygon
          points="578.67 428.42 577.24 427.59 578.66 426.77 580.09 427.6 578.67 428.42"
          fill="#787878"
        />
        <path
          d="M585.34,428A6.68,6.68,0,0,1,572,428c0-3.69,3-17.14,6.67-17.14S585.34,424.32,585.34,428Z"
          fill="#acacac"
        />
        <polygon
          points="704.1 474.6 702.68 473.78 701.25 474.6 701.26 474.6 701.25 474.6 701.25 481.21 701.25 481.21 701.25 486.58 701.25 486.58 701.25 486.58 701.25 486.58 701.26 486.58 702.68 487.41 704.11 486.58 704.1 486.58 704.11 486.58 704.11 481.21 704.11 479.97 704.11 474.6 704.1 474.6"
          fill="#787878"
        />
        <polygon
          points="701.25 474.6 701.25 481.21 701.25 481.21 701.25 486.58 701.25 486.58 701.26 486.58 702.68 487.4 702.68 474.6 701.25 474.6"
          fill="#787878"
        />
        <polygon
          points="702.68 475.42 701.25 474.6 702.68 473.78 704.11 474.6 702.68 475.42"
          fill="#787878"
        />
        <path
          d="M709.35,475A6.67,6.67,0,0,1,696,475c0-3.69,3-17.15,6.67-17.15S709.35,471.33,709.35,475Z"
          fill="#acacac"
        />
        <polygon
          points="597.76 436.5 596.34 435.67 594.91 436.49 594.92 436.5 594.91 436.5 594.91 443.11 594.91 443.11 594.91 448.48 594.91 448.48 594.91 448.48 594.91 448.48 594.92 448.48 596.35 449.3 597.77 448.48 597.76 448.48 597.77 448.48 597.77 443.11 597.77 441.87 597.77 436.5 597.76 436.5"
          fill="#787878"
        />
        <polygon
          points="594.91 436.5 594.91 443.11 594.91 443.11 594.91 448.48 594.91 448.48 594.92 448.48 596.34 449.3 596.34 436.5 594.91 436.5"
          fill="#787878"
        />
        <polygon
          points="596.35 437.32 594.91 436.49 596.34 435.67 597.77 436.5 596.35 437.32"
          fill="#787878"
        />
        <path
          d="M603,436.91a6.67,6.67,0,1,1-13.34,0c0-3.68,3-17.14,6.67-17.14S603,433.23,603,436.91Z"
          fill="#acacac"
        />
        <polygon
          points="397.32 529.63 395.9 528.81 394.47 529.63 394.48 529.63 394.47 529.63 394.47 536.24 394.47 536.24 394.47 541.61 394.47 541.61 394.47 541.61 394.47 541.61 394.48 541.61 395.91 542.43 397.33 541.61 397.32 541.61 397.33 541.61 397.33 536.24 397.33 535 397.33 529.63 397.32 529.63"
          fill="#787878"
        />
        <polygon
          points="394.47 529.63 394.47 536.24 394.47 536.24 394.47 541.61 394.47 541.61 394.48 541.61 395.9 542.43 395.9 529.63 394.47 529.63"
          fill="#787878"
        />
        <polygon
          points="395.91 530.45 394.47 529.63 395.9 528.81 397.33 529.63 395.91 530.45"
          fill="#787878"
        />
        <path
          d="M402.57,530.05a6.67,6.67,0,0,1-13.34,0c0-3.69,3-17.15,6.67-17.15S402.57,526.36,402.57,530.05Z"
          fill="#acacac"
        />
        <path
          d="M108.23,605.56a.27.27,0,0,0-.27.27.26.26,0,0,0-.26-.27.27.27,0,0,0,0,.54.24.24,0,0,0,.14-.05,4.34,4.34,0,0,0-.14.89.27.27,0,0,0,.53,0,5.18,5.18,0,0,0-.14-.89.24.24,0,0,0,.14.05.27.27,0,0,0,0-.54Z"
          fill="#9e9e9e"
        />
      </svg>
    </div>
  </div>
</template>

<script>
// import * as types from '@/store/types';

export default {
  name: 'uello-app-404',
};
</script>

<style>
body {
  margin: 0;
}
.text-redirect {
  font-size: 30px;
  text-align: center;
  user-select: none;
}

.error-svg {
  display: block;
}

#window-1 {
  animation: light 15s infinite alternate;
  animation-timing-function: steps(2);
}

#window-2 {
  animation: light 17s infinite alternate;
  animation-timing-function: steps(2);
}

#window-3 {
  animation: light 15s infinite alternate;
  animation-timing-function: steps(2);
}

#window-4 {
  animation: light 16s infinite alternate;
  animation-timing-function: steps(2);
}

#window-5 {
  animation: light 16s infinite alternate;
  animation-timing-function: steps(2);
}

#window-6 {
  animation: light 10s infinite alternate;
  animation-timing-function: steps(2);
}

#window-7 {
  animation: light 17s infinite alternate;
  animation-timing-function: steps(2);
}

#window-8 {
  animation: light 20s infinite alternate;
  animation-timing-function: steps(2);
}

#window-9 {
  animation: light 10s infinite alternate;
  animation-timing-function: steps(2);
}

#window-10 {
  animation: light 10s infinite alternate;
  animation-timing-function: steps(2);
}

#window-11 {
  animation: light 11s infinite alternate;
  animation-timing-function: steps(2);
}

#window-12 {
  animation: light 18s infinite alternate;
  animation-timing-function: steps(2);
}

#window-13 {
  animation: light 16s infinite alternate;
  animation-timing-function: steps(2);
}

#window-14 {
  animation: light 16s infinite alternate;
  animation-timing-function: steps(2);
}

#window-15 {
  animation: light 17s infinite alternate;
  animation-timing-function: steps(2);
}

#window-16 {
  animation: light 13s infinite alternate;
  animation-timing-function: steps(2);
}

#car-3,
#car-4,
#car-5,
#car-7,
#car-8 {
  transform-origin: center center;
  transform-box: fill-box;
}

#car-1 {
  animation: car1 30s infinite linear;
}

#car-2 {
  animation: car2 30s infinite linear;
}

#car-3 {
  animation: car3 15s infinite linear;
}

#car-4 {
  animation: car4 15s infinite linear;
}

#car-5 {
  animation: car5 30s infinite linear;
}

#car-6 {
  animation: car6 30s infinite linear;
}

#car-7 {
  animation: car7 15s infinite linear;
}

#car-8 {
  animation: car8 10s infinite linear;
}

#car-9 {
  animation: car9 15s infinite linear;
}

@keyframes car1 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-23.25%, -150px);
  }
  30% {
    transform: translate(-23.25%, -128px);
  }
  50% {
    transform: translate(0, 22px);
  }
  70% {
    transform: translate(46.5%, 322px);
  }
  80% {
    transform: translate(46.5%, 300px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes car2 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(46.5%, 300px);
  }
  21% {
    transform: translate(46.5%, 278px);
  }
  50% {
    transform: translate(0, -22px);
  }
  70% {
    transform: translate(-23.25%, -172px);
  }
  80% {
    transform: translate(-23.25%, -150px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes car3 {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(70px, -40px);
  }
  11% {
    transform: translate(70px, -40px) rotateY(180deg);
  }
  50% {
    transform: translate(550px, 240px) rotateY(180deg);
  }
  51% {
    transform: translate(70px, 248px) rotateY(180deg);
  }
  75% {
    transform: translate(-180px, 105px) rotateY(180deg);
  }
  76% {
    transform: translate(-180px, 105px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes car4 {
  0% {
    transform: translate(0, 0);
  }
  15% {
    transform: translate(-87.5px, 50px);
  }
  16% {
    transform: translate(-87.5px, 50px) rotateY(180deg);
  }
  31% {
    transform: translate(-206px, -16px) rotateY(180deg);
  }
  32% {
    transform: translate(-206px, -16px);
  }
  47% {
    transform: translate(-80px, -80px);
  }
  74% {
    transform: translate(-90px, -90px) rotateY(180deg);
  }
  89% {
    transform: translate(44px, -24px) rotateY(180deg);
  }
  90% {
    transform: translate(44px, -24px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes car5 {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(150px, 82px);
  }
  52% {
    transform: translate(720px, 82px);
  }
  72% {
    transform: translate(210px, -210px);
  }
  73% {
    transform: translate(210px, -210px) rotateY(180deg);
  }
  94% {
    transform: translate(-75px, -45px) rotateY(180deg);
  }
  95% {
    transform: translate(-75px, -45px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes car6 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  60% {
    transform: translate(-31%, -200px);
    opacity: 1;
  }
  61% {
    transform: translate(-31%, -200px);
    opacity: 0;
  }
  62% {
    transform: translate(38.25%, 250px);
    opacity: 0;
  }
  63% {
    transform: translate(38.25%, 250px);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes car7 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  46% {
    transform: translate(460px, 265px);
    opacity: 1;
  }
  47% {
    transform: translate(460px, 265px);
    opacity: 0;
  }
  48% {
    transform: translate(-100px, 45px) rotateY(180deg);
    opacity: 0;
  }
  79% {
    transform: translate(-100px, 45px) rotateY(180deg);
    opacity: 1;
  }
  94% {
    transform: translate(-16px, -6px) rotateY(180deg);
    opacity: 1;
  }
  95% {
    transform: translate(-16px, -6px);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes car8 {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  20% {
    transform: translate(-25px, 16px);
  }
  21% {
    transform: translate(-25px, 16px) rotateY(180deg);
    opacity: 1;
  }
  40% {
    transform: translate(-120px, -34px) rotateY(180deg);
    opacity: 1;
  }
  41% {
    transform: translate(-120px, -34px);
    opacity: 0;
  }
  42% {
    transform: translate(80px, -40px);
    opacity: 0;
  }
  75% {
    transform: translate(80px, -40px);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes car9 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(31%, -200px);
  }
  21% {
    transform: translate(31%, -222px);
  }
  50% {
    transform: translate(0, -22px);
  }
  60% {
    transform: translate(-7.75%, 28px);
  }
  61% {
    transform: translate(-7.75%, 50px);
  }
  90% {
    transform: translate(-7.75%, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
